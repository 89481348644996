/* ==========================================================================
   Components.Connect
   ========================================================================== */

.c-Connect {
    padding: 10px $Base-containerPadding;

    @include media(">=medium") {
        padding: 0;
    }
}

.c-Connect--menu {
    padding: 0;
}

.c-Connect-list {
    @include media(">=medium") {
        display: inline-block;
        text-align: left;
    }

    @include media(">=large") {
        display: block;
        text-align: right;
    }
}

.c-Connect-item {
    @include media(">=medium") {
        display: inline-block;
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }

    @include media(">=1024px") {
        display: inline-block;
        margin-left: 50px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.c-Connect-icon {
    color: #ea5419;

    &.icon-phone {
        width: 20px;
        height: 20px;

        @include media(">=medium") {
            width: 25px;
            height: 25px;
        }
    }

    &.icon-user {
        @include media(">=medium") {
            width: 25px;
            height: 25px;
        }
    }

    &.icon-hands {
        @include media(">=medium") {
            width: 20px;
            height: 25px;
        }
    }
}

.c-Connect-value {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;

    .c-Connect--menu & {
        margin-left: 0;
    }

    > span {
        color: #666;
    }

    > a {
        .c-Connect--menu & {
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            text-decoration: none;
        }
    }

    @include media(">=medium") {
        margin-left: 0;
    }

    @include media(">=1024px") {
        margin-left: 5px;
    }
}
