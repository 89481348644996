/* ==========================================================================
   Components.Menu
   ========================================================================== */

.c-Menu {
    padding: 5px 0;
    background-color: #f4f4f4;
    font-weight: 500;

    @include media(">=medium") {
        padding: 0;
        text-align: center;
    }

    @include media(">=large") {
        @include rem(font-size, 18px);
        border-top: 1px solid #ccc;
    }
}

.c-Menu-list {
    @include media(">=medium") {
        position: relative;
        z-index: 20;
    }
}

.c-Menu-item {
    position: relative;

    > span {
        @include rem(font-size, 12px);
        position: absolute;
        top: 11px;
        left: 165px;
        height: 20px;
        padding: 0 10px;
        border-radius: 10px;
        background-color: #ff3300;
        color: #fff;
        line-height: 20px;
    }

    @include media(">=medium") {
        display: inline-block;
        margin-left: 40px;

        &:first-child {
            margin-left: 0;
        }

        > span {
            top: 10px;
            right: -8px;
            left: auto;
        }
    }

    @include media(">=1024px") {
        margin-left: 60px;
    }
}

.c-Menu-link {
    position: relative;
    display: block;
    padding: 10px 0;
    text-decoration: none;

    @include media(">=medium") {
        display: inline-block;
        padding: 30px 0;

        > span {
            position: absolute;
            bottom: 0;
            left: 0;
            display: none;
            width: 100%;
            height: 5px;
            background: #999;
            transform: scale3d(0, 1, 1);
            transition: transform 0.1s;
        }

        .no-touch &,
        &.is-active {
            > span {
                display: block;
            }
        }

        &.is-active {
            > span {
                transform: scale3d(1, 1, 1);
            }
        }

        &:hover {
            .no-touch & {
                > span {
                    transform: scale3d(1, 1, 1);
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: 0.3s;
                }
            }
        }
    }
}

// .c-Menu-link.o-Color--alpha {
//     .no-touch & {
//         &:before {
//             background: $Base-colorAlpha;
//         }
//     }
// }

// .c-Menu-link.o-Color--beta {
//     .no-touch & {
//         &:before {
//             background: $Base-colorBeta;
//         }
//     }
// }

// .c-Menu-link.o-Color--gamma {
//     .no-touch & {
//         &:before {
//             background: $Base-colorGamma;
//         }
//     }
// }
