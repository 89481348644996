/* ==========================================================================
   Components.Button
   ========================================================================== */

.c-Button {
    padding: 5px 10px;
    border: none;

    @include media(">=medium") {
        padding: 10px 15px;
    }

    @include media(">=large") {
        padding: 10px 25px;
    }
}

.c-Button--fill {
    border: 1px solid;
    color: #fff;
    text-decoration: underline;

    &.c-Button--huge {
        @include rem(font-size, 18px);
        padding: 10px 20px;
        border-width: 2px;
    }

    &.c-Button--alpha {
        border-color: $Base-colorAlpha;
        background-color: $Base-colorAlpha;
    }

    &.c-Button--beta {
        border-color: $Base-colorBeta;
        background-color: $Base-colorBeta;
    }

    &.c-Button--gamma {
        border-color: $Base-colorGamma;
        background-color: $Base-colorGamma;
    }

    &:hover {
        border-color: #333;
        background-color: transparent;
        color: #333;
        text-decoration: none;

        // &.c-Button--alpha {
        //     color: $Base-colorAlpha;
        // }

        // &.c-Button--beta {
        //     color: $Base-colorBeta;
        // }

        // &.c-Button--gamma {
        //     color: $Base-colorGamma;
        // }
    }

    @include media(">=medium") {
        &.c-Button--huge {
            @include rem(font-size, 26px);
            padding: 20px 30px;
        }
    }

    @include media(">=large") {
        &.c-Button--huge {
            @include rem(font-size, 30px);
            padding: 30px 40px;
        }
    }
}

.c-Button--link {
    position: relative;
    display: inline-block;
    padding: 0 2px 2px;
    font-weight: 600;

    > span {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        transform: scale3d(1, 1, 1);
        transition: transform 0.1s;
    }

    &:hover {
        .no-touch & {
            > span {
                transform: scale3d(0, 1, 1);
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 0.3s;
            }
        }
    }

    &.c-Button--huge {
        @include rem(font-size, 18px);
        padding: 0 5px 5px;

        > span {
            height: 4px;
        }
    }

    @include media(">=medium") {
        @include rem(font-size, 18px);

        &.c-Button--huge {
            @include rem(font-size, 20px);
        }
    }

    @include media(">=large") {
        &.c-Button--huge {
            @include rem(font-size, 26px);
        }
    }
}

.c-Button--cube {
    @include rem(font-size, 18px);
    padding: 0;
    font-weight: 600;

    > span {
        position: relative;
        display: block;
        padding: $Button-padding;
        background-color: #fff;

        > span {
            display: none;
        }
    }

    @include media(">=medium") {
        @include rem(font-size, 20px);

        .no-touch & {
            perspective: 1000px;

            > span {
                transition: transform 0.3s;
                transform-origin: 50% 0;
                transform-style: preserve-3d;

                > span {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: $Button-padding;
                    background-color: #fff;
                    transition: background 0.3s;
                    transform: rotateX(-90deg);
                    transform-origin: 50% 0;
                }
            }
        }

        &:hover {
            .no-touch & {
                > span {
                    transform: rotateX(90deg) translateY(-22px);
                }
            }
        }
    }

    @include media(">=large") {
        @include rem(font-size, 24px);
    }
}
