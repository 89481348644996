/* ==========================================================================
   Settings.Defaults
   ========================================================================== */

/**
 * Breakpoints
 */

$breakpoints: (
    small: 480px,
    medium: 768px,
    large: 1220px
);
$breakpointsLength: length($breakpoints);



/**
 * Fonts
 */

$Font-defaultSans:                Arial, 'Helvetica Neue', Helvetica, sans-serif;
$Font-defaultSerif:               Times, Georgia, 'Times New Roman', serif;

$Font-alpha:                      'Montserrat', sans-serif;



/**
 * Project basic vars
 */

$Base-colorAlpha:                 #33addf;
$Base-colorBeta:                  #f59c10;
$Base-colorGamma:                 #51b048;

$Base-colorBackground:            #fff;
$Base-colorText:                  #000;
$Base-colorLinks:                 inherit;

$Base-selectionBackground:        $Base-colorText;
$Base-selectionText:              $Base-colorBackground;

$Base-fontFamily:                 $Font-alpha;
$Base-fontSize:                   14px;
$Base-fontSizeMediumUp:           16px;
$Base-fontSizeLargeUp:            16px;

$Base-lineHeight:                 1.5 * $Base-fontSize;
$Base-lineHeightMediumUp:         1.5 * $Base-fontSizeMediumUp;
$Base-lineHeightLargeUp:          1.5 * $Base-fontSizeLargeUp;

$Base-normalWeight:               300;
$Base-strongWeight:               600;

$Base-unitV:                      20px;
$Base-unitH:                      20px;

$Base-containerWidth:             1180px;
$Base-containerPadding:           20px;
$Base-containerPaddingMediumUp:   20px;
