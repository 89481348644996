/* ==========================================================================
   Components.Carousel
   ========================================================================== */

.c-Carousel {
    background-color: #f0f0f0;
}

.c-Carousel-list {
    padding: 20px 0 65px;

    @include media(">=medium") {
        padding: 30px 0 95px;
        margin: 0 -25px;
    }
}

.c-Carousel-item {
    position: relative;
    height: auto;
    // padding: 0 10px;

    @include media(">=medium") {
        padding: 0 25px;
    }
}

.c-Carousel-prev,
.c-Carousel-next {
    position: absolute;
    bottom: ((65px - 15px) / 2);
    z-index: 5;

    &.slick-disabled {
        display: none !important;
    }

    @include media(">=medium") {
        bottom: ((95px - 15px) / 2);
    }
}

.c-Carousel-prev {
    right: 50%;
    margin-right: 10px;
}

.c-Carousel-next {
    left: 50%;
    margin-left: 10px;
}

.c-Carousel-icon {
    width: 27px;
    height: 15px;
    color: #666;
    transition: color 0.3s;

    .c-Carousel-prev:hover &,
    .c-Carousel-next:hover & {
        color: #000;
    }

}
