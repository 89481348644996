/* ==========================================================================
   Objects.Color
   ========================================================================== */

.o-Color--alpha {
    color: $Base-colorAlpha;
}

.o-Color--beta {
    color: $Base-colorBeta;
}

.o-Color--gamma {
    color: $Base-colorGamma;
}

.o-Color--gray {
    color: #999;
}
