/* ==========================================================================
   Object.List
   ========================================================================== */

.o-ListBare {
    padding: 0;
    list-style: none;
}

.o-ListInline {
    margin: 0;

    > li {
        display: inline-block;
    }
}
