/* ==========================================================================
   Components.Collapse
   ========================================================================== */

.c-Collapse {
    @include media(">=medium") {
        position: sticky;
        top: 0;
        z-index: 50;

        &.collapse,
        &.in {
            display: block;
            height: auto !important;
        }
    }
}
