/* ==========================================================================
   Components.Page
   ========================================================================== */

.c-Page {
    background-color: $Base-colorBackground;
    color: $Base-colorText;

    & *::-moz-selection {
        background: $Base-selectionBackground;
        color: $Base-selectionText;
        text-shadow: none;
    }

    & *::selection {
        background: $Base-selectionBackground;
        color: $Base-selectionText;
        text-shadow: none;
    }

    @include media(">=medium") {
        line-height: $Base-lineHeightMediumUp / $Base-fontSizeMediumUp;
    }

    @include media(">=large") {
        line-height: $Base-lineHeightLargeUp / $Base-fontSizeLargeUp;
    }
}

.c-Page-body {
    @include rem(font-size, $Base-fontSize);
    position: relative;

    @include media(">=medium") {
        @include rem(font-size, $Base-fontSizeMediumUp);
    }

    @include media(">=large") {
        @include rem(font-size, $Base-fontSizeLargeUp);
    }
}

.c-Page-inner {
    position: relative;
}

.c-Page-bgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;

    > .o-Container {
        position: relative;
    }
}

.c-Page-bgs--left {
    @include media(">=1420px") {
        > .o-Container {
            position: static;
        }
    }
}

.c-Page-bgs--right {
    @include media(">=2000px") {
        > .o-Container {
            position: static;
        }
    }
}

.c-Page-bg {
    position: absolute;
    display: none;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;

    @include media(">=large") {
        display: block;
    }
}

.c-Page-bg--left {
    top: 0;
    left: -100px;
    z-index: 10;
    width: 265px;
    height: 781px;
    background-image: url('../images/backgrounds/bg.left.svg');

    .no-svg & {
        background-image: url('../images/backgrounds/bg.left.png');
    }

    @include media(">=1420px") {
        left: 0;
    }
}

.c-Page-bg--right {
    top: -35px;
    right: -390px;
    z-index: -1;
    width: 962px;
    height: 1174px;
    background-image: url('../images/backgrounds/bg.right.svg');

    .no-svg & {
        background-image: url('../images/backgrounds/bg.right.png');
    }

    @include media(">=2000px") {
        right: 0;
    }
}

.c-Page-stripes {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 7px;
    height: 100%;
    background-color: $Base-colorAlpha;

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 7px;
        height: 22%;
        border-bottom: 6px solid #fff;
    }

    &:before {
        top: 0;
        background-color: $Base-colorGamma;
    }

    &:after {
        top: 22%;
        background-color: $Base-colorBeta;
    }
}
