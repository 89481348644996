/* ==========================================================================
   Base.Fonts
   ========================================================================== */

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima-nova/proximanova-light-webfont.eot');
    src: url('../fonts/proxima-nova/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima-nova/proximanova-light-webfont.woff2') format('woff2'),
         url('../fonts/proxima-nova/proximanova-light-webfont.woff') format('woff'),
         url('../fonts/proxima-nova/proximanova-light-webfont.ttf') format('truetype'),
         url('../fonts/proxima-nova/proximanova-light-webfont.svg#proxima_novalight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima-nova/proximanova-regular-webfont.eot');
    src: url('../fonts/proxima-nova/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima-nova/proximanova-regular-webfont.woff2') format('woff2'),
         url('../fonts/proxima-nova/proximanova-regular-webfont.woff') format('woff'),
         url('../fonts/proxima-nova/proximanova-regular-webfont.ttf') format('truetype'),
         url('../fonts/proxima-nova/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima-nova/proximanova-bold-webfont.eot');
    src: url('../fonts/proxima-nova/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima-nova/proximanova-bold-webfont.woff2') format('woff2'),
         url('../fonts/proxima-nova/proximanova-bold-webfont.woff') format('woff'),
         url('../fonts/proxima-nova/proximanova-bold-webfont.ttf') format('truetype'),
         url('../fonts/proxima-nova/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/montserrat-light-webfont.eot');
    src: url('../fonts/montserrat/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat/montserrat-light-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-light-webfont.woff') format('woff'),
         url('../fonts/montserrat/montserrat-light-webfont.ttf') format('truetype'),
         url('../fonts/montserrat/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/montserrat-semibold-webfont.eot');
    src: url('../fonts/montserrat/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat/montserrat-semibold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-semibold-webfont.woff') format('woff'),
         url('../fonts/montserrat/montserrat-semibold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat/montserrat-semibold-webfont.svg#montserratsemi_bold') format('svg');
    font-weight: 600;
    font-style: normal;
}

// @font-face {
//     font-family: 'Montserrat';
//     src: url('../fonts/montserrat/montserrat-bold-webfont.eot');
//     src: url('../fonts/montserrat/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2'),
//          url('../fonts/montserrat/montserrat-bold-webfont.woff') format('woff'),
//          url('../fonts/montserrat/montserrat-bold-webfont.ttf') format('truetype'),
//          url('../fonts/montserrat/montserrat-bold-webfont.svg#montserratbold') format('svg');
//     font-weight: 700;
//     font-style: normal;
// }
