/* ==========================================================================
   Components.Directory
   ========================================================================== */

.c-Directory {
    position: relative;
    text-align: center;

    @include media(">=medium") {
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            width: 50%;
            height: 140px;
        }

        &:before {
            left: 0;
            background-color: $Base-colorAlpha;
        }

        &:after {
            left: 50%;
            background-color: $Base-colorBeta;
        }
    }
}

.c-Directory-list {
    @include media(">=medium") {
        font-size: 0;
    }
}

.c-Directory-item {
    @include rem(font-size, 18px);
    margin-top: 20px;
    line-height: 1.3;
    overflow: hidden;
    vertical-align: top;

    @include media(">=medium") {
        display: inline-block;
        width: percentage(1 / 3);
        margin-top: 0;

        &:first-child {
            border-right: 10px solid #fff;
        }

        &:nth-child(2) {
            border-right: 10px solid #fff;
            border-left: 10px solid #fff;
        }

        &:last-child {
            border-left: 10px solid #fff;
        }
    }
}

.c-Directory-icon {
    float: left;
    display: block;
    width: 70px;
    height: 70px;
    margin-right: 20px;
    margin-bottom: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80% 80%;

    @include media(">=small") {
        width: 140px;
        height: 140px;
        background-size: auto auto;
    }

    @include media(">=medium") {
        float: none;
        width: 100%;
        height: 140px;
        margin-bottom: 30px;
        background-size: auto auto;
    }
}

.c-Directory-icon--sprava {
    background-color: $Base-colorAlpha;
    background-image: url('../images/icons/sprava.png');

    @include media(">=medium") {
        background-color: transparent;
    }
}

.c-Directory-icon--aktivity {
    background-color: $Base-colorGamma;
    background-image: url('../images/icons/aktivity.png');
}

.c-Directory-icon--helpdesk {
    background-color: $Base-colorBeta;
    background-image: url('../images/icons/helpdesk.png');

    @include media(">=medium") {
        background-color: transparent;
    }
}

.c-Directory-link {
    display: block;
    overflow: hidden;
    text-align: left;
    text-decoration: none;

    &:hover {
        color: #666;
    }

    @include media(">=medium") {
        display: inline-block;
    }
}
