/* ==========================================================================
   Utils.Size
   ========================================================================== */

/* Intrinsic widths
   ========================================================================== */

/**
 * Make an element shrink wrap its content.
 */

.u-sizeFit,
.u-sizeFitAlt {
    display: block !important;
    float: left !important;
    width: auto !important;
}

.u-sizeFitAlt {
    float: right !important;
}

/**
 * Make an element fill the remaining space.
 * N.B. This will hide overflow.
 */

.u-sizeFill {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
}

/**
 * An alternative method to make an element fill the remaining space.
 * N.B. Do not use if child elements might be wider than the remaining space.
 * In Chrome, Safari, and Firefox it results in undesired layout.
 */

.u-sizeFillAlt {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
}

/**
 * Make an element the width of its parent.
 */

.u-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
}

.u-size1of1 {
    width: 100% !important;
}



@for $i from 1 through $breakpointsLength {
    $breakpoint: nth($breakpoints, $i);
    $breakpointName: nth($breakpoint, 1);

    @if $i < $breakpointsLength {
        $breakpointNext: nth($breakpoints, $i + 1);
        $breakpointNameNext: nth($breakpointNext, 1);

        @include media(#{'>=' + $breakpointName}, #{'<' + $breakpointNameNext}) {
            .u-sizeFit\@#{$breakpointName}Only,
            .u-sizeFitAlt\@#{$breakpointName}Only {
                display: block !important;
                float: left !important;
                width: auto !important;
            }

            .u-sizeFitAlt\@#{$breakpointName}Only {
                float: right !important;
            }

            .u-sizeFill\@#{$breakpointName}Only {
                display: block !important;
                overflow: hidden !important;
                width: auto !important;
            }

            .u-sizeFillAlt\@#{$breakpointName}Only {
                display: table-cell !important;
                max-width: 100% !important;
                width: 10000px !important;
            }

            .u-sizeFull\@#{$breakpointName}Only {
                box-sizing: border-box !important;
                display: block !important;
                width: 100% !important;
            }

            .u-size1of1\@#{$breakpointName}Only {
                width: 100% !important;
            }
        }
    }

    @include media(#{'>=' + $breakpointName}) {
        .u-sizeFit\@#{$breakpointName}Up,
        .u-sizeFitAlt\@#{$breakpointName}Up {
            display: block !important;
            float: left !important;
            width: auto !important;
        }

        .u-sizeFitAlt\@#{$breakpointName}Up {
            float: right !important;
        }

        .u-sizeFill\@#{$breakpointName}Up {
            display: block !important;
            overflow: hidden !important;
            width: auto !important;
        }

        .u-sizeFillAlt\@#{$breakpointName}Up {
            display: table-cell !important;
            max-width: 100% !important;
            width: 10000px !important;
        }

        .u-sizeFull\@#{$breakpointName}Up {
            box-sizing: border-box !important;
            display: block !important;
            width: 100% !important;
        }

        .u-size1of1\@#{$breakpointName}Up {
            width: 100% !important;
        }
    }

    @include media(#{'<' + $breakpointName}) {
        .u-sizeFit\@#{$breakpointName}Down,
        .u-sizeFitAlt\@#{$breakpointName}Down {
            display: block !important;
            float: left !important;
            width: auto !important;
        }

        .u-sizeFitAlt\@#{$breakpointName}Down {
            float: right !important;
        }

        .u-sizeFill\@#{$breakpointName}Down {
            display: block !important;
            overflow: hidden !important;
            width: auto !important;
        }

        .u-sizeFillAlt\@#{$breakpointName}Down {
            display: table-cell !important;
            max-width: 100% !important;
            width: 10000px !important;
        }

        .u-sizeFull\@#{$breakpointName}Down {
            box-sizing: border-box !important;
            display: block !important;
            width: 100% !important;
        }

        .u-size1of1\@#{$breakpointName}Down {
            width: 100% !important;
        }
    }
}



/* Proportional widths
   ========================================================================== */

@include columns(2, 3, 4, 5, 8);
