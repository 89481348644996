/* ==========================================================================
   Generic.Box-Sizing
   ========================================================================== */

/**
 * Global `box-sizing` settings.
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */

* {
    &,
    &:before,
    &:after {
        box-sizing: inherit;
    }
}
