/* ==========================================================================
   Components.Hamburger
   ========================================================================== */

.c-Hamburger {
    position: relative;
    display: inline-block;
    padding: 9px;
    margin: 0;
    border: none;
    border-radius: 3px;
    background: $Base-colorAlpha;
    color: $Base-colorAlpha;
    line-height: 1;
    text-transform: uppercase;
    vertical-align: middle;
}

    .c-Hamburger-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: #fff;

        & + & {
            margin-top: 4px;
        }
    }
