/* ==========================================================================
   Components.Partners
   ========================================================================== */

.c-Partners {
    text-align: center;
}

.c-Partners-inner {
    margin-bottom: 20px;
    overflow: hidden;
    text-align: left;

    @include media(">=medium") {
        margin-bottom: 50px;
    }
}

.c-Partners-list {
    margin-bottom: -2px;
    margin-right: -2px;
    font-size: 0;
}

.c-Partners-item {
    position: relative;
    display: inline-block;
    width: percentage(1 / 3);
    vertical-align: top;

    &:before,
    &:after {
        content: "";
        position: absolute;
        background-color: #ccc;
    }

    &:before {
        bottom: 0;
        left: 5%;
        width: 90%;
        height: 1px;
    }

    &:after {
        top: 5%;
        right: 0;
        width: 1px;
        height: 90%;
    }

    @include media(">=small") {
        width: percentage(1 / 4);
    }

    @include media(">=medium") {
        width: percentage(1 / 5);
    }
}

.c-Partners-link {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 100%;
}

.c-Partners-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);

    @include media(">=large") {
        width: auto;
    }
}
