/* ==========================================================================
   Objects.Grid
   ========================================================================== */

/**
 * The grid system uses `box-sizing: border-box;` and
 * `display: inline-block;` to create an extremely powerful, flexible
 * alternative to the traditional grid system. Combine the layout items with
 * the widths found in `utils.size`.
 */

$Grid-gutter:            25px !default;
$Grid-gutter--quarter:   quarter($Grid-gutter) !default;
$Grid-gutter--third:     third($Grid-gutter) !default;
$Grid-gutter--halve:     halve($Grid-gutter) !default;
$Grid-gutter--double:    double($Grid-gutter) !default;
$Grid-gutter--treble:    treble($Grid-gutter) !default;
$Grid-gutter--quadruple: quadruple($Grid-gutter) !default;

$Grid--flush:            false !default;
$Grid--quarter:          false !default;
$Grid--third:            false !default;
$Grid--halve:            true  !default;
$Grid--double:           true  !default;
$Grid--treble:           true  !default;
$Grid--quadruple:        true  !default;

$Grid--rev:              true  !default;
$Grid--middle:           true  !default;
$Grid--bottom:           false !default;
$Grid--center:           false !default;
$Grid--right:            false !default;



/**
 * Begin a grid group.
 *
 * 1. Remove white space between `inline-block` elements.
 */

.o-Grid {
    padding: 0;
    margin:  0;
    margin-left: -$Grid-gutter;
    font-size: 0; /* [1] */
    list-style: none;
}




/**
 * 1. Cause columns to stack side-by-side.
 * 2. Space columns apart.
 * 3. Align columns to the tops of each other.
 * 4. Full-width unless told to behave otherwise.
 * 5. Set default `font-size` which set to 0 on parent container.
 */

.o-Grid-item  {
    @include rem(font-size, $Base-fontSize); /* [5] */
    display: inline-block; /* [1] */
    width: 100%; /* [4] */
    padding-left: $Grid-gutter; /* [2] */
    vertical-align: top; /* [3] */

    @include media(">=medium") {
        @include rem(font-size, $Base-fontSizeMediumUp); /* [5] */
    }

    @include media(">=large") {
        @include rem(font-size, $Base-fontSizeLargeUp); /* [5] */
    }
}



@if ($Grid--quarter == true) {
    .o-Grid--quarter {
        margin-left: -($Grid-gutter--quarter);

        > .o-Grid-item {
            padding-left: $Grid-gutter--quarter;
        }
    }
}

@if ($Grid--third == true) {
    .o-Grid--third {
        margin-left: -($Grid-gutter--third);

        > .o-Grid-item {
            padding-left: $Grid-gutter--third;
        }
    }
}

@if ($Grid--halve == true) {
    .o-Grid--halve {
        margin-left: -($Grid-gutter--halve);

        > .o-Grid-item {
            padding-left: $Grid-gutter--halve;
        }
    }
}

@if ($Grid--double == true) {
    .o-Grid--double {
        margin-left: -($Grid-gutter--double);

        > .o-Grid-item {
            padding-left: $Grid-gutter--double;
        }
    }
}

@if ($Grid--treble == true) {
    .o-Grid--treble {
        margin-left: -($Grid-gutter--treble);

        > .o-Grid-item {
            padding-left: $Grid-gutter--treble;
        }
    }
}

@if ($Grid--quadruple == true) {
    .o-Grid--quadruple {
        margin-left: -($Grid-gutter--quadruple);

        > .o-Grid-item {
            padding-left: $Grid-gutter--quadruple;
        }
    }
}

@if ($Grid--flush == true) {
    .o-Grid--flush {
        margin-left: 0;

        > .o-Grid-item {
            padding-left: 0;
        }
    }
}

@if ($Grid--rev == true) {
    .o-Grid--rev {
        direction: rtl;
        text-align: left;

        > .o-Grid-item {
            direction: ltr;
            text-align: left;
        }
    }
}

@if ($Grid--middle == true) {
    .o-Grid--middle {
        > .o-Grid-item {
            vertical-align: middle;
        }
    }
}

@if ($Grid--bottom == true) {
    .o-Grid--bottom {
        > .o-Grid-item {
            vertical-align: bottom;
        }
    }
}

@if ($Grid--center == true) {
    .o-Grid--center {
        text-align: center;

        > .o-Grid-item {
            text-align: left;
        }
    }
}

@if ($Grid--right == true) {
    .o-Grid--right {
        text-align: right;

        > .o-Grid-item {
            text-align: left;
        }
    }
}

@for $i from 1 through $breakpointsLength {
    $breakpoint: nth($breakpoints, $i);
    $breakpointName: nth($breakpoint, 1);

    @if $i < $breakpointsLength {
        $breakpointNext: nth($breakpoints, $i + 1);
        $breakpointNameNext: nth($breakpointNext, 1);

        @include media(#{'>=' + $breakpointName}, #{'<' + $breakpointNameNext}) {
            .o-Grid--quarter\@#{$breakpointName}Only {
                margin-left: -($Grid-gutter--quarter);

                > .o-Grid-item {
                    padding-left: $Grid-gutter--quarter;
                }
            }

            .o-Grid--double\@#{$breakpointName}Only {
                margin-left: -($Grid-gutter--double);

                > .o-Grid-item {
                    padding-left: $Grid-gutter--double;
                }
            }

            .o-Grid--treble\@#{$breakpointName}Only {
                margin-left: -($Grid-gutter--treble);

                > .o-Grid-item {
                    padding-left: $Grid-gutter--treble;
                }
            }

            .o-Grid--quadruple\@#{$breakpointName}Only {
                margin-left: -($Grid-gutter--quadruple);

                > .o-Grid-item {
                    padding-left: $Grid-gutter--quadruple;
                }
            }
        }
    }

    @include media(#{'>=' + $breakpointName}) {
        .o-Grid--quarter\@#{$breakpointName}Up {
            margin-left: -($Grid-gutter--quarter);

            > .o-Grid-item {
                padding-left: $Grid-gutter--quarter;
            }
        }

        .o-Grid--double\@#{$breakpointName}Up {
            margin-left: -($Grid-gutter--double);

            > .o-Grid-item {
                padding-left: $Grid-gutter--double;
            }
        }

        .o-Grid--treble\@#{$breakpointName}Up {
            margin-left: -($Grid-gutter--treble);

            > .o-Grid-item {
                padding-left: $Grid-gutter--treble;
            }
        }

        .o-Grid--quadruple\@#{$breakpointName}Up {
            margin-left: -($Grid-gutter--quadruple);

            > .o-Grid-item {
                padding-left: $Grid-gutter--quadruple;
            }
        }
    }

    @include media(#{'<' + $breakpointName}) {
        .o-Grid--quarter\@#{$breakpointName}Down {
            margin-left: -($Grid-gutter--quarter);

            > .o-Grid-item {
                padding-left: $Grid-gutter--quarter;
            }
        }

        .o-Grid--double\@#{$breakpointName}Down {
            margin-left: -($Grid-gutter--double);

            > .o-Grid-item {
                padding-left: $Grid-gutter--double;
            }
        }

        .o-Grid--treble\@#{$breakpointName}Down {
            margin-left: -($Grid-gutter--treble);

            > .o-Grid-item {
                padding-left: $Grid-gutter--treble;
            }
        }

        .o-Grid--quadruple\@#{$breakpointName}Down {
            margin-left: -($Grid-gutter--quadruple);

            > .o-Grid-item {
                padding-left: $Grid-gutter--quadruple;
            }
        }
    }
}
