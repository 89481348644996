/* ==========================================================================
   Components.Buttons
   ========================================================================== */

.c-Buttons {
    @include rem(font-size, 16px);
    font-weight: normal;
}

.c-Buttons-item {
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }
}

.c-Buttons-link {
    display: block;
    padding: 0 10px;
    border: 1px solid;
    text-decoration: none;

    > span {
        display: inline-block;
        padding: 20px 0;
        text-decoration: underline;
        vertical-align: middle;
    }

    &:hover {
        background-color: transparent !important;
        border-color: #333 !important;

        > span {
            text-decoration: none;
        }
    }

    @include media(">=medium") {
        display: inline-block;
        min-width: 320px;
        padding: 0 15px;
    }

    @include media(">=large") {
        min-width: 350px;
        padding: 0 25px;
    }
}

.c-Buttons-icon {
    width: 40px;
    margin-right: 10px;
    color: #fff;

    .c-Buttons-link:hover & {
        color: #333;
    }

    > svg {
        width: auto;
        height: 40px;
    }

    @include media(">=large") {
        margin-right: 20px;
    }
}

.c-Buttons-label {
    color: #fff;

    .c-Buttons-link:hover & {
        color: #333;
    }
}
