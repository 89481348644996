/* ==========================================================================
   Objects.Container
   ========================================================================== */

.o-Container {
    max-width: $Base-containerWidth + (2 * $Base-containerPadding);
    padding: 0 $Base-containerPadding;
    margin: 0 auto;

    .o-Container {
        padding: 0;
    }
}

.o-Container--narrow {
    max-width: 960px + (2 * $Base-containerPadding);
}
