/* ==========================================================================
   Components.Header
   ========================================================================== */

.c-Header {
    padding-top: 10px + 23px + 10px;

    @include media(">=medium") {
        padding-top: 0;
    }
}

.c-Header-row {
    padding: 10px $Base-containerPadding;
    margin: 0 (-$Base-containerPadding);
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;

    @include media(">=medium") {
        position: relative;
        z-index: 20;
        padding: 15px 0;
        margin: 0;
        border: none;
    }
}

.c-Header-logo {
    @include media(">=medium") {
        float: left;
    }
}

.c-Header-connect {
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0 (-$Base-containerPadding);

    @include media(">=medium") {
        position: static;
        width: auto;
        padding: ((100px - 25px) / 2) 0;
        margin: 0;
        overflow: hidden;
        text-align: right;
    }

    @include media(">=1024px") {
        padding: ((100px - 25px) / 2) 0;
    }
}

.c-Header-hamburger {
    position: absolute;
    top: 10px + 23px + 10px + 10px + 1px + ((40px - 32) / 2);
    right: $Base-containerPadding;
}

.c-Header-overview {
    padding: 10px 0;
    background-color: #f4f4f4;

    @include media(">=medium") {
        padding: 30px 0;
    }
}

.c-Header-title {
    @include rem(font-size, 20px);
    margin-bottom: halve($Base-unitV);
    font-weight: 600;
    line-height: 1.4;

    @include media(">=medium") {
        @include rem(font-size, 24px);
        line-height: 1.3;
    }

    @include media(">=large") {
        @include rem(font-size, 30px);
    }
}

.c-Header-subtitle {
    margin-bottom: 0;
    color: #fd7d28;
    font-weight: normal;
}
