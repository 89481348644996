@charset "UTF-8";
/* ==========================================================================
   Main
   ========================================================================== */
/**
 * Settings
 */
/* ==========================================================================
   Settings.Defaults
   ========================================================================== */
/**
 * Breakpoints
 */
/**
 * Fonts
 */
/**
 * Project basic vars
 */
/**
 * Tools
 */
/* ==========================================================================
   Tools.Functions
   ========================================================================== */
/**
 * Function for rem mixin which removes units.
 */
/**
 * Basic fractions:
 */
/* ==========================================================================
   Tools.Mixins
   ========================================================================== */
/**
 * Generates column classes based on the defined breakpoints,
 * named with the convention `.u-sizexofy@breakpoint`, where
 * `x` is a subdivision of `y`.
 *
 * @include columns(3, 4);
 *
 * Generates:
 *  .u-size1of3@smallOnly
 *  .u-size1of3@smallUp
 *  .u-size1of3@smallDown
 *  .u-size1of3@mediumOnly
 *  .u-size1of3@mediumUp
 *  .u-size1of3@mediumDown
 *  .u-size1of3@largeUp
 *  .u-size1of3@largeDown
 *  .u-size2of3@smallOnly
 *  .u-size2of3@smallUp
 *  .u-size2of3@smallDown
 *  .u-size2of3@mediumOnly
 *  .u-size2of3@mediumUp
 *  .u-size2of3@mediumDown
 *  .u-size2of3@largeUp
 *  .u-size2of3@largeDown
 *
 *  (...)
 */
/**
 * Mixin for rem units within px fallback.
 * http://css-tricks.com/snippets/css/less-mixin-for-rem-font-sizing/
 *
 * .foo {
 *     @include rem(margin, 10, 20, 30, 40);
 * }
 *
 * .foo {
 *     margin: 160px 320px 480px 640px;
 *     margin: 10rem 20rem 30rem 40rem;
 * }
 *
 */
@media (min-width: 480px) {
  body::after {
    content: '{"small":{"value": "480px", "active": true}, "medium":{"value": "768px", "active": false}, "large":{"value": "1220px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}

@media (min-width: 768px) {
  body::after {
    content: '{"small":{"value": "480px", "active": true}, "medium":{"value": "768px", "active": true}, "large":{"value": "1220px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}

@media (min-width: 1220px) {
  body::after {
    content: '{"small":{"value": "480px", "active": true}, "medium":{"value": "768px", "active": true}, "large":{"value": "1220px", "active": true}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}

/**
 * Generic
 */
/* ==========================================================================
   Generic.Reset
   ========================================================================== */
/**
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
ol,
p,
pre,
ul {
  margin: 0;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

iframe {
  border: 0;
}

/* ==========================================================================
   Generic.Shared
   ========================================================================== */
/**
 * Where `margin-bottom` is concerned,this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
blockquote,
dl,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
ul,
table {
  margin-bottom: 20px;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Same `line-height` for basic form elements.
 */
button,
input {
  line-height: 1.5;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */
[tabindex="-1"]:focus {
  outline: none !important;
}

/* ==========================================================================
   Generic.Box-Sizing
   ========================================================================== */
/**
 * Global `box-sizing` settings.
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */
*, *:before, *:after {
  box-sizing: inherit;
}

/**
 * Base
 */
/* ==========================================================================
   Base.Fonts
   ========================================================================== */
@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/proxima-nova/proximanova-light-webfont.eot");
  src: url("../fonts/proxima-nova/proximanova-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima-nova/proximanova-light-webfont.woff2") format("woff2"), url("../fonts/proxima-nova/proximanova-light-webfont.woff") format("woff"), url("../fonts/proxima-nova/proximanova-light-webfont.ttf") format("truetype"), url("../fonts/proxima-nova/proximanova-light-webfont.svg#proxima_novalight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/proxima-nova/proximanova-regular-webfont.eot");
  src: url("../fonts/proxima-nova/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima-nova/proximanova-regular-webfont.woff2") format("woff2"), url("../fonts/proxima-nova/proximanova-regular-webfont.woff") format("woff"), url("../fonts/proxima-nova/proximanova-regular-webfont.ttf") format("truetype"), url("../fonts/proxima-nova/proximanova-regular-webfont.svg#proxima_nova_rgregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/proxima-nova/proximanova-bold-webfont.eot");
  src: url("../fonts/proxima-nova/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima-nova/proximanova-bold-webfont.woff2") format("woff2"), url("../fonts/proxima-nova/proximanova-bold-webfont.woff") format("woff"), url("../fonts/proxima-nova/proximanova-bold-webfont.ttf") format("truetype"), url("../fonts/proxima-nova/proximanova-bold-webfont.svg#proxima_nova_rgbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/montserrat-light-webfont.eot");
  src: url("../fonts/montserrat/montserrat-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-light-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-light-webfont.woff") format("woff"), url("../fonts/montserrat/montserrat-light-webfont.ttf") format("truetype"), url("../fonts/montserrat/montserrat-light-webfont.svg#montserratlight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/montserrat-regular-webfont.eot");
  src: url("../fonts/montserrat/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-regular-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-regular-webfont.woff") format("woff"), url("../fonts/montserrat/montserrat-regular-webfont.ttf") format("truetype"), url("../fonts/montserrat/montserrat-regular-webfont.svg#montserratregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/montserrat-semibold-webfont.eot");
  src: url("../fonts/montserrat/montserrat-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-semibold-webfont.woff2") format("woff2"), url("../fonts/montserrat/montserrat-semibold-webfont.woff") format("woff"), url("../fonts/montserrat/montserrat-semibold-webfont.ttf") format("truetype"), url("../fonts/montserrat/montserrat-semibold-webfont.svg#montserratsemi_bold") format("svg");
  font-weight: 600;
  font-style: normal;
}

/* ==========================================================================
   Base.Page
   ========================================================================== */
/**
 * High-, page-level styling.
 *
 * 1. Prevent margin and border from affecting element width.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 * 3. Set the default `font-size`, `font-weight` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 4. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 5. Prevent certain mobile browsers from automatically zooming fonts.
 */
html {
  box-sizing: border-box;
  /* [1] */
  min-height: 100%;
  /* [2] */
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  /* [3] */
  line-height: 1.5;
  /* [3] */
  overflow-y: scroll;
  /* [4] */
  -webkit-text-size-adjust: 100%;
  /* [5] */
  -ms-text-size-adjust: 100%;
  /* [5] */
}

/* ==========================================================================
   Base.Typography
   ========================================================================== */
/**
 * Basic links behavior.
 */
a {
  color: inherit;
}

a:hover,
a:active {
  text-decoration: none;
}

a:focus {
  outline: 0;
}

/**
 * Basic strong weight.
 */
b,
strong {
  font-weight: 600;
}

/**
 * Give a help cursor to elements that give extra info on `:hover`.
 */
abbr[title],
dfn[title] {
  cursor: help;
}

/* ==========================================================================
   Base.Images
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 */
img {
  max-width: 100%;
  /* [1] */
  font-style: italic;
  /* [2] */
}

/**
 * 1. Google Maps breaks if `max-width: 100%` acts upon it; use their selector
 *    to remove the effects.
 * 2. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
.gm-style img,
img[width],
img[height] {
  /* [2] */
  max-width: none;
}

/**
 * Objects
 */
/* ==========================================================================
   Objects.Button
   ========================================================================== */
.o-Button {
  position: relative;
  display: inline-block;
  padding: 5px 20px;
  margin: 0;
  border-color: currentcolor;
  border-style: solid;
  border-width: 1px;
  background: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
}

.o-Button:hover, .o-Button:focus, .o-Button:active {
  text-decoration: none;
}

.o-Button:focus {
  outline: 0;
}

.o-Button:disabled {
  cursor: default;
  opacity: 0.6;
}

.o-Button--stretch {
  width: 100%;
}

.o-ButtonBare {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  text-decoration: none;
  user-select: none;
  vertical-align: baseline;
}

.o-ButtonBare:hover, .o-ButtonBare:focus, .o-ButtonBare:active {
  text-decoration: none;
}

.o-ButtonBare:focus {
  outline: 0;
}

/* ==========================================================================
   Objects.Color
   ========================================================================== */
.o-Color--alpha {
  color: #33addf;
}

.o-Color--beta {
  color: #f59c10;
}

.o-Color--gamma {
  color: #51b048;
}

.o-Color--gray {
  color: #999;
}

/* ==========================================================================
   Objects.Container
   ========================================================================== */
.o-Container {
  max-width: 1220px;
  padding: 0 20px;
  margin: 0 auto;
}

.o-Container .o-Container {
  padding: 0;
}

.o-Container--narrow {
  max-width: 1000px;
}

/* ==========================================================================
   Objects.Embed
   ========================================================================== */
.o-Embed {
  position: relative;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.o-Embed > iframe,
.o-Embed > object,
.o-Embed > embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   Objects.Grid
   ========================================================================== */
/**
 * The grid system uses `box-sizing: border-box;` and
 * `display: inline-block;` to create an extremely powerful, flexible
 * alternative to the traditional grid system. Combine the layout items with
 * the widths found in `utils.size`.
 */
/**
 * Begin a grid group.
 *
 * 1. Remove white space between `inline-block` elements.
 */
.o-Grid {
  padding: 0;
  margin: 0;
  margin-left: -25px;
  font-size: 0;
  /* [1] */
  list-style: none;
}

/**
 * 1. Cause columns to stack side-by-side.
 * 2. Space columns apart.
 * 3. Align columns to the tops of each other.
 * 4. Full-width unless told to behave otherwise.
 * 5. Set default `font-size` which set to 0 on parent container.
 */
.o-Grid-item {
  font-size: 14px;
  font-size: 0.875rem;
  /* [5] */
  display: inline-block;
  /* [1] */
  width: 100%;
  /* [4] */
  padding-left: 25px;
  /* [2] */
  vertical-align: top;
  /* [3] */
}

@media (min-width: 768px) {
  .o-Grid-item {
    font-size: 16px;
    font-size: 1rem;
    /* [5] */
  }
}

@media (min-width: 1220px) {
  .o-Grid-item {
    font-size: 16px;
    font-size: 1rem;
    /* [5] */
  }
}

.o-Grid--halve {
  margin-left: -13px;
}

.o-Grid--halve > .o-Grid-item {
  padding-left: 13px;
}

.o-Grid--double {
  margin-left: -50px;
}

.o-Grid--double > .o-Grid-item {
  padding-left: 50px;
}

.o-Grid--treble {
  margin-left: -75px;
}

.o-Grid--treble > .o-Grid-item {
  padding-left: 75px;
}

.o-Grid--quadruple {
  margin-left: -100px;
}

.o-Grid--quadruple > .o-Grid-item {
  padding-left: 100px;
}

.o-Grid--rev {
  direction: rtl;
  text-align: left;
}

.o-Grid--rev > .o-Grid-item {
  direction: ltr;
  text-align: left;
}

.o-Grid--middle > .o-Grid-item {
  vertical-align: middle;
}

@media (min-width: 480px) and (max-width: 767px) {
  .o-Grid--quarter\@smallOnly {
    margin-left: -6px;
  }
  .o-Grid--quarter\@smallOnly > .o-Grid-item {
    padding-left: 6px;
  }
  .o-Grid--double\@smallOnly {
    margin-left: -50px;
  }
  .o-Grid--double\@smallOnly > .o-Grid-item {
    padding-left: 50px;
  }
  .o-Grid--treble\@smallOnly {
    margin-left: -75px;
  }
  .o-Grid--treble\@smallOnly > .o-Grid-item {
    padding-left: 75px;
  }
  .o-Grid--quadruple\@smallOnly {
    margin-left: -100px;
  }
  .o-Grid--quadruple\@smallOnly > .o-Grid-item {
    padding-left: 100px;
  }
}

@media (min-width: 480px) {
  .o-Grid--quarter\@smallUp {
    margin-left: -6px;
  }
  .o-Grid--quarter\@smallUp > .o-Grid-item {
    padding-left: 6px;
  }
  .o-Grid--double\@smallUp {
    margin-left: -50px;
  }
  .o-Grid--double\@smallUp > .o-Grid-item {
    padding-left: 50px;
  }
  .o-Grid--treble\@smallUp {
    margin-left: -75px;
  }
  .o-Grid--treble\@smallUp > .o-Grid-item {
    padding-left: 75px;
  }
  .o-Grid--quadruple\@smallUp {
    margin-left: -100px;
  }
  .o-Grid--quadruple\@smallUp > .o-Grid-item {
    padding-left: 100px;
  }
}

@media (max-width: 479px) {
  .o-Grid--quarter\@smallDown {
    margin-left: -6px;
  }
  .o-Grid--quarter\@smallDown > .o-Grid-item {
    padding-left: 6px;
  }
  .o-Grid--double\@smallDown {
    margin-left: -50px;
  }
  .o-Grid--double\@smallDown > .o-Grid-item {
    padding-left: 50px;
  }
  .o-Grid--treble\@smallDown {
    margin-left: -75px;
  }
  .o-Grid--treble\@smallDown > .o-Grid-item {
    padding-left: 75px;
  }
  .o-Grid--quadruple\@smallDown {
    margin-left: -100px;
  }
  .o-Grid--quadruple\@smallDown > .o-Grid-item {
    padding-left: 100px;
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .o-Grid--quarter\@mediumOnly {
    margin-left: -6px;
  }
  .o-Grid--quarter\@mediumOnly > .o-Grid-item {
    padding-left: 6px;
  }
  .o-Grid--double\@mediumOnly {
    margin-left: -50px;
  }
  .o-Grid--double\@mediumOnly > .o-Grid-item {
    padding-left: 50px;
  }
  .o-Grid--treble\@mediumOnly {
    margin-left: -75px;
  }
  .o-Grid--treble\@mediumOnly > .o-Grid-item {
    padding-left: 75px;
  }
  .o-Grid--quadruple\@mediumOnly {
    margin-left: -100px;
  }
  .o-Grid--quadruple\@mediumOnly > .o-Grid-item {
    padding-left: 100px;
  }
}

@media (min-width: 768px) {
  .o-Grid--quarter\@mediumUp {
    margin-left: -6px;
  }
  .o-Grid--quarter\@mediumUp > .o-Grid-item {
    padding-left: 6px;
  }
  .o-Grid--double\@mediumUp {
    margin-left: -50px;
  }
  .o-Grid--double\@mediumUp > .o-Grid-item {
    padding-left: 50px;
  }
  .o-Grid--treble\@mediumUp {
    margin-left: -75px;
  }
  .o-Grid--treble\@mediumUp > .o-Grid-item {
    padding-left: 75px;
  }
  .o-Grid--quadruple\@mediumUp {
    margin-left: -100px;
  }
  .o-Grid--quadruple\@mediumUp > .o-Grid-item {
    padding-left: 100px;
  }
}

@media (max-width: 767px) {
  .o-Grid--quarter\@mediumDown {
    margin-left: -6px;
  }
  .o-Grid--quarter\@mediumDown > .o-Grid-item {
    padding-left: 6px;
  }
  .o-Grid--double\@mediumDown {
    margin-left: -50px;
  }
  .o-Grid--double\@mediumDown > .o-Grid-item {
    padding-left: 50px;
  }
  .o-Grid--treble\@mediumDown {
    margin-left: -75px;
  }
  .o-Grid--treble\@mediumDown > .o-Grid-item {
    padding-left: 75px;
  }
  .o-Grid--quadruple\@mediumDown {
    margin-left: -100px;
  }
  .o-Grid--quadruple\@mediumDown > .o-Grid-item {
    padding-left: 100px;
  }
}

@media (min-width: 1220px) {
  .o-Grid--quarter\@largeUp {
    margin-left: -6px;
  }
  .o-Grid--quarter\@largeUp > .o-Grid-item {
    padding-left: 6px;
  }
  .o-Grid--double\@largeUp {
    margin-left: -50px;
  }
  .o-Grid--double\@largeUp > .o-Grid-item {
    padding-left: 50px;
  }
  .o-Grid--treble\@largeUp {
    margin-left: -75px;
  }
  .o-Grid--treble\@largeUp > .o-Grid-item {
    padding-left: 75px;
  }
  .o-Grid--quadruple\@largeUp {
    margin-left: -100px;
  }
  .o-Grid--quadruple\@largeUp > .o-Grid-item {
    padding-left: 100px;
  }
}

@media (max-width: 1219px) {
  .o-Grid--quarter\@largeDown {
    margin-left: -6px;
  }
  .o-Grid--quarter\@largeDown > .o-Grid-item {
    padding-left: 6px;
  }
  .o-Grid--double\@largeDown {
    margin-left: -50px;
  }
  .o-Grid--double\@largeDown > .o-Grid-item {
    padding-left: 50px;
  }
  .o-Grid--treble\@largeDown {
    margin-left: -75px;
  }
  .o-Grid--treble\@largeDown > .o-Grid-item {
    padding-left: 75px;
  }
  .o-Grid--quadruple\@largeDown {
    margin-left: -100px;
  }
  .o-Grid--quadruple\@largeDown > .o-Grid-item {
    padding-left: 100px;
  }
}

/* ==========================================================================
   Objects.Icon
   ========================================================================== */
.o-Icon {
  display: inline-block;
  background: none;
  vertical-align: middle;
}

.o-Icon > svg {
  width: inherit;
  height: inherit;
  fill: currentColor;
  vertical-align: top;
}

/* ==========================================================================
   Object.List
   ========================================================================== */
.o-ListBare {
  padding: 0;
  list-style: none;
}

.o-ListInline {
  margin: 0;
}

.o-ListInline > li {
  display: inline-block;
}

/* ==========================================================================
   Objects.Size
   ========================================================================== */
.o-Size--14 {
  font-size: 14px;
  font-size: 0.875rem;
}

/**
 * Libs
 */
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #333333;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #777777;
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.428571429;
  color: #777777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**
 * Components
 */
/* ==========================================================================
   Components.Button
   ========================================================================== */
.c-Button {
  padding: 5px 10px;
  border: none;
}

@media (min-width: 768px) {
  .c-Button {
    padding: 10px 15px;
  }
}

@media (min-width: 1220px) {
  .c-Button {
    padding: 10px 25px;
  }
}

.c-Button--fill {
  border: 1px solid;
  color: #fff;
  text-decoration: underline;
}

.c-Button--fill.c-Button--huge {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 20px;
  border-width: 2px;
}

.c-Button--fill.c-Button--alpha {
  border-color: #33addf;
  background-color: #33addf;
}

.c-Button--fill.c-Button--beta {
  border-color: #f59c10;
  background-color: #f59c10;
}

.c-Button--fill.c-Button--gamma {
  border-color: #51b048;
  background-color: #51b048;
}

.c-Button--fill:hover {
  border-color: #333;
  background-color: transparent;
  color: #333;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-Button--fill.c-Button--huge {
    font-size: 26px;
    font-size: 1.625rem;
    padding: 20px 30px;
  }
}

@media (min-width: 1220px) {
  .c-Button--fill.c-Button--huge {
    font-size: 30px;
    font-size: 1.875rem;
    padding: 30px 40px;
  }
}

.c-Button--link {
  position: relative;
  display: inline-block;
  padding: 0 2px 2px;
  font-weight: 600;
}

.c-Button--link > span {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scale3d(1, 1, 1);
  transition: transform 0.1s;
}

.no-touch .c-Button--link:hover > span {
  transform: scale3d(0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}

.c-Button--link.c-Button--huge {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0 5px 5px;
}

.c-Button--link.c-Button--huge > span {
  height: 4px;
}

@media (min-width: 768px) {
  .c-Button--link {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .c-Button--link.c-Button--huge {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 1220px) {
  .c-Button--link.c-Button--huge {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.c-Button--cube {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0;
  font-weight: 600;
}

.c-Button--cube > span {
  position: relative;
  display: block;
  padding: 5px 20px;
  background-color: #fff;
}

.c-Button--cube > span > span {
  display: none;
}

@media (min-width: 768px) {
  .c-Button--cube {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .no-touch .c-Button--cube {
    perspective: 1000px;
  }
  .no-touch .c-Button--cube > span {
    transition: transform 0.3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
  }
  .no-touch .c-Button--cube > span > span {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 20px;
    background-color: #fff;
    transition: background 0.3s;
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
  }
  .no-touch .c-Button--cube:hover > span {
    transform: rotateX(90deg) translateY(-22px);
  }
}

@media (min-width: 1220px) {
  .c-Button--cube {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

/* ==========================================================================
   Components.Page
   ========================================================================== */
.c-Page {
  background-color: #fff;
  color: #000;
}

.c-Page *::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

.c-Page *::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

@media (min-width: 768px) {
  .c-Page {
    line-height: 1.5;
  }
}

@media (min-width: 1220px) {
  .c-Page {
    line-height: 1.5;
  }
}

.c-Page-body {
  font-size: 14px;
  font-size: 0.875rem;
  position: relative;
}

@media (min-width: 768px) {
  .c-Page-body {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 1220px) {
  .c-Page-body {
    font-size: 16px;
    font-size: 1rem;
  }
}

.c-Page-inner {
  position: relative;
}

.c-Page-bgs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.c-Page-bgs > .o-Container {
  position: relative;
}

@media (min-width: 1420px) {
  .c-Page-bgs--left > .o-Container {
    position: static;
  }
}

@media (min-width: 2000px) {
  .c-Page-bgs--right > .o-Container {
    position: static;
  }
}

.c-Page-bg {
  position: absolute;
  display: none;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@media (min-width: 1220px) {
  .c-Page-bg {
    display: block;
  }
}

.c-Page-bg--left {
  top: 0;
  left: -100px;
  z-index: 10;
  width: 265px;
  height: 781px;
  background-image: url("../images/backgrounds/bg.left.svg");
}

.no-svg .c-Page-bg--left {
  background-image: url("../images/backgrounds/bg.left.png");
}

@media (min-width: 1420px) {
  .c-Page-bg--left {
    left: 0;
  }
}

.c-Page-bg--right {
  top: -35px;
  right: -390px;
  z-index: -1;
  width: 962px;
  height: 1174px;
  background-image: url("../images/backgrounds/bg.right.svg");
}

.no-svg .c-Page-bg--right {
  background-image: url("../images/backgrounds/bg.right.png");
}

@media (min-width: 2000px) {
  .c-Page-bg--right {
    right: 0;
  }
}

.c-Page-stripes {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 7px;
  height: 100%;
  background-color: #33addf;
}

.c-Page-stripes:before, .c-Page-stripes:after {
  content: "";
  position: absolute;
  left: 0;
  width: 7px;
  height: 22%;
  border-bottom: 6px solid #fff;
}

.c-Page-stripes:before {
  top: 0;
  background-color: #51b048;
}

.c-Page-stripes:after {
  top: 22%;
  background-color: #f59c10;
}

/* ==========================================================================
   Components.Bar
   ========================================================================== */
.c-Bar {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 5px 0;
  background-color: #f0544f;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

@media (min-width: 768px) {
  .c-Bar {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.c-Bar--muted {
  font-size: 11px;
  font-size: 0.6875rem;
  background-color: #f4f4f4;
  color: #666;
  font-weight: normal;
}

.c-Bar-desc {
  margin-bottom: 0;
}

/* ==========================================================================
   Components.Header
   ========================================================================== */
.c-Header {
  padding-top: 43px;
}

@media (min-width: 768px) {
  .c-Header {
    padding-top: 0;
  }
}

.c-Header-row {
  padding: 10px 20px;
  margin: 0 -20px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

@media (min-width: 768px) {
  .c-Header-row {
    position: relative;
    z-index: 20;
    padding: 15px 0;
    margin: 0;
    border: none;
  }
}

@media (min-width: 768px) {
  .c-Header-logo {
    float: left;
  }
}

.c-Header-connect {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0 -20px;
}

@media (min-width: 768px) {
  .c-Header-connect {
    position: static;
    width: auto;
    padding: 37.5px 0;
    margin: 0;
    overflow: hidden;
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .c-Header-connect {
    padding: 37.5px 0;
  }
}

.c-Header-hamburger {
  position: absolute;
  top: 58px;
  right: 20px;
}

.c-Header-overview {
  padding: 10px 0;
  background-color: #f4f4f4;
}

@media (min-width: 768px) {
  .c-Header-overview {
    padding: 30px 0;
  }
}

.c-Header-title {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .c-Header-title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.3;
  }
}

@media (min-width: 1220px) {
  .c-Header-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.c-Header-subtitle {
  margin-bottom: 0;
  color: #fd7d28;
  font-weight: normal;
}

/* ==========================================================================
   Components.Logo
   ========================================================================== */
.c-Logo {
  display: inline-block;
}

.c-Logo-image {
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 767px) {
  .c-Logo-image {
    height: 40px;
  }
}

/* ==========================================================================
   Components.Connect
   ========================================================================== */
.c-Connect {
  padding: 10px 20px;
}

@media (min-width: 768px) {
  .c-Connect {
    padding: 0;
  }
}

.c-Connect--menu {
  padding: 0;
}

@media (min-width: 768px) {
  .c-Connect-list {
    display: inline-block;
    text-align: left;
  }
}

@media (min-width: 1220px) {
  .c-Connect-list {
    display: block;
    text-align: right;
  }
}

@media (min-width: 768px) {
  .c-Connect-item {
    display: inline-block;
    margin-left: 10px;
  }
  .c-Connect-item:first-child {
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .c-Connect-item {
    display: inline-block;
    margin-left: 50px;
  }
  .c-Connect-item:first-child {
    margin-left: 0;
  }
}

.c-Connect-icon {
  color: #ea5419;
}

.c-Connect-icon.icon-phone {
  width: 20px;
  height: 20px;
}

@media (min-width: 768px) {
  .c-Connect-icon.icon-phone {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 768px) {
  .c-Connect-icon.icon-user {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 768px) {
  .c-Connect-icon.icon-hands {
    width: 20px;
    height: 25px;
  }
}

.c-Connect-value {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

.c-Connect--menu .c-Connect-value {
  margin-left: 0;
}

.c-Connect-value > span {
  color: #666;
}

.c-Connect--menu .c-Connect-value > a {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-Connect-value {
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .c-Connect-value {
    margin-left: 5px;
  }
}

/* ==========================================================================
   Components.Hamburger
   ========================================================================== */
.c-Hamburger {
  position: relative;
  display: inline-block;
  padding: 9px;
  margin: 0;
  border: none;
  border-radius: 3px;
  background: #33addf;
  color: #33addf;
  line-height: 1;
  text-transform: uppercase;
  vertical-align: middle;
}

.c-Hamburger-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
}

.c-Hamburger-bar + .c-Hamburger-bar {
  margin-top: 4px;
}

/* ==========================================================================
   Components.Collapse
   ========================================================================== */
@media (min-width: 768px) {
  .c-Collapse {
    position: sticky;
    top: 0;
    z-index: 50;
  }
  .c-Collapse.collapse, .c-Collapse.in {
    display: block;
    height: auto !important;
  }
}

/* ==========================================================================
   Components.Menu
   ========================================================================== */
.c-Menu {
  padding: 5px 0;
  background-color: #f4f4f4;
  font-weight: 500;
}

@media (min-width: 768px) {
  .c-Menu {
    padding: 0;
    text-align: center;
  }
}

@media (min-width: 1220px) {
  .c-Menu {
    font-size: 18px;
    font-size: 1.125rem;
    border-top: 1px solid #ccc;
  }
}

@media (min-width: 768px) {
  .c-Menu-list {
    position: relative;
    z-index: 20;
  }
}

.c-Menu-item {
  position: relative;
}

.c-Menu-item > span {
  font-size: 12px;
  font-size: 0.75rem;
  position: absolute;
  top: 11px;
  left: 165px;
  height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #ff3300;
  color: #fff;
  line-height: 20px;
}

@media (min-width: 768px) {
  .c-Menu-item {
    display: inline-block;
    margin-left: 40px;
  }
  .c-Menu-item:first-child {
    margin-left: 0;
  }
  .c-Menu-item > span {
    top: 10px;
    right: -8px;
    left: auto;
  }
}

@media (min-width: 1024px) {
  .c-Menu-item {
    margin-left: 60px;
  }
}

.c-Menu-link {
  position: relative;
  display: block;
  padding: 10px 0;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-Menu-link {
    display: inline-block;
    padding: 30px 0;
  }
  .c-Menu-link > span {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 5px;
    background: #999;
    transform: scale3d(0, 1, 1);
    transition: transform 0.1s;
  }
  .no-touch .c-Menu-link > span, .c-Menu-link.is-active > span {
    display: block;
  }
  .c-Menu-link.is-active > span {
    transform: scale3d(1, 1, 1);
  }
  .no-touch .c-Menu-link:hover > span {
    transform: scale3d(1, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;
  }
}

/* ==========================================================================
   Components.Content
   ========================================================================== */
.c-Content-section,
.c-Content-landing {
  padding: 20px 0;
}

@media (min-width: 768px) {
  .c-Content-section,
  .c-Content-landing {
    padding: 50px 0;
  }
}

.c-Content-section--zero {
  padding: 0;
}

.c-Content-section--collapse {
  padding-top: 0;
}

.c-Content-section--shrink {
  margin-bottom: -20px;
}

@media (min-width: 768px) {
  .c-Content-section--shrink {
    margin-bottom: -50px;
  }
}

.c-Content-section--background {
  background-color: #f0f0f0;
}

@media (min-width: 768px) {
  .c-Content-section--cells {
    padding-bottom: 30px;
  }
}

@media (min-width: 1220px) {
  .c-Content-section--cells {
    padding-bottom: 50px;
  }
}

.c-Content-title {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .c-Content-title {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.2;
  }
}

@media (min-width: 1220px) {
  .c-Content-title {
    font-size: 42px;
    font-size: 2.625rem;
  }
}

@media (min-width: 1220px) {
  .c-Content-title--product {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

.c-Content-subtitle {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .c-Content-subtitle {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.2;
  }
}

@media (min-width: 1220px) {
  .c-Content-subtitle {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

@media (min-width: 768px) {
  .c-Content-subtitle--smaller {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

@media (min-width: 1220px) {
  .c-Content-subtitle--smaller {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.c-Content-gallery {
  margin-bottom: 20px;
}

.c-Content-video {
  margin-bottom: 20px;
}

.c-Content-Cta {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .c-Content-Cta {
    margin-top: 100px;
  }
}

@media (min-width: 768px) {
  .c-Content-Cta--hero {
    margin-top: 40px;
  }
}

.c-Content-Cta--zero {
  margin-top: 0;
}

.c-Content-Cta--arrow {
  margin-top: 0;
}

.c-Content-Cta--arrow:before {
  content: "";
  display: block;
  width: 200px;
  height: 217px;
  margin: 0 auto 20px;
  margin: 0 auto;
  background: url("../images/icons/arrow.cta.png") no-repeat left bottom;
}

@media (min-width: 768px) {
  .c-Content-section--collapse\@mediumUp {
    padding-top: 0;
  }
}

/* ==========================================================================
   Components.Breadcrumbs
   ========================================================================== */
.c-Breadcrumbs {
  padding: 20px 0 10px;
  color: #ccc;
}

@media (min-width: 768px) {
  .c-Breadcrumbs {
    padding: 40px 0 30px;
  }
}

.c-Breadcrumbs-item + .c-Breadcrumbs-item:before {
  content: "\2013 ";
  display: inline-block;
}

/* ==========================================================================
   Components.Slider
   ========================================================================== */
.c-Slider {
  position: relative;
  padding-bottom: 54px;
}

.c-Slider-item {
  height: auto;
  padding: 20px 0;
  background-position: left center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .c-Slider-item {
    padding: 0;
    background-size: 45% auto;
  }
}

@media (min-width: 1220px) {
  .c-Slider-item {
    background-position: 10% center;
  }
}

@media (max-width: 767px) {
  .c-Slider-item {
    background: transparent !important;
  }
}

@media (min-width: 768px) {
  .c-Slider-inner {
    position: relative;
    height: 0;
    padding-bottom: 38.9830508475%;
  }
}

.c-Slider-body {
  padding-left: 1px;
}

@media (min-width: 768px) {
  .c-Slider-body {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    padding-left: 0;
    transform: translateY(-50%);
  }
}

@media (min-width: 1220px) {
  .c-Slider-body {
    width: 38.9830508475%;
  }
}

.c-Slider-label {
  width: 100%;
  height: 90px;
  margin-bottom: 10px;
  margin-left: -20px;
}

@media (max-width: 1219px) {
  .c-Slider-label {
    height: 60px;
    margin-left: -10px;
  }
}

.c-Slider-title {
  font-size: 22px;
  font-size: 1.375rem;
  min-height: 79.2px;
  font-weight: 600;
  line-height: 1.2;
}

.c-Slider-title > strong {
  color: #33addf;
  font-weight: inherit;
}

@media (min-width: 480px) {
  .c-Slider-title {
    min-height: 52.8px;
  }
}

@media (min-width: 768px) {
  .c-Slider-title {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media (min-width: 1220px) {
  .c-Slider-title {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 1;
  }
}

.c-Slider-dots {
  position: absolute;
  bottom: -34px;
  left: 50%;
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateX(-50%);
}

.c-Slider-dots > li {
  display: inline-block;
}

.c-Slider-dots > li > button {
  width: 14px;
  height: 14px;
  padding: 0;
  margin: 0 5px;
  border: none;
  border-radius: 50%;
  background: #999;
  cursor: pointer;
  font-size: 0;
}

.c-Slider-dots > li > button:focus {
  outline: 0;
}

.c-Slider-dots > li.slick-active > button {
  background-color: #000;
}

/* ==========================================================================
   Components.Hero
   ========================================================================== */
.c-Hero {
  padding: 120px 0 0;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100px;
}

@media (min-width: 768px) {
  .c-Hero {
    padding: 30px 0 30px 45%;
    background-position: 15% center;
    background-size: auto 100%;
  }
}

.c-Hero--solid {
  padding-top: 0;
}

@media (min-width: 768px) {
  .c-Hero--solid {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .c-Hero--zero {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* ==========================================================================
   Components.Support
   ========================================================================== */
.c-Support {
  font-size: 16px;
  font-size: 1rem;
  color: #cc0000;
  font-weight: 600;
}

@media (min-width: 768px) {
  .c-Support {
    font-size: 20px;
    font-size: 1.25rem;
    text-align: center;
  }
}

@media (min-width: 1220px) {
  .c-Support {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.c-Support-item {
  position: relative;
  margin-top: 20px;
  padding-left: 75px;
}

.c-Support-item:first-child {
  margin-top: 0;
}

.c-Support-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #cc0000;
}

@media (min-width: 768px) {
  .c-Support-item {
    display: inline-block;
    padding-left: 135px;
    margin: 0 40px;
    text-align: left;
  }
  .c-Support-item:before {
    width: 120px;
    height: 120px;
  }
}

.c-Support-icon {
  position: absolute;
  color: #fff;
}

.c-Support-icon.icon-user {
  top: 15px;
  left: 15px;
  width: 30px;
  height: 30px;
}

.c-Support-icon.icon-hands {
  top: 15px;
  left: 18px;
  width: 24px;
  height: 30px;
}

@media (min-width: 768px) {
  .c-Support-icon.icon-user {
    top: 22.5px;
    left: 22.5px;
    width: 75px;
    height: 75px;
  }
  .c-Support-icon.icon-hands {
    top: 22.5px;
    left: 30px;
    width: 60px;
    height: 75px;
  }
}

.c-Support-link {
  display: inline-block;
  margin: 6px 0;
}

@media (min-width: 768px) {
  .c-Support-link {
    margin: 30px 0;
  }
}

@media (min-width: 1220px) {
  .c-Support-link {
    margin: 24px 0;
  }
}

/* ==========================================================================
   Components.Form
   ========================================================================== */
.c-Form {
  position: relative;
  z-index: 10;
}

.c-Form-legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 20px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .c-Form-legend {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.c-Form-item {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-Form-item {
    margin-bottom: 30px;
  }
}

.c-Form-label {
  display: block;
  margin-bottom: 5px;
  margin-left: 10px;
  font-weight: normal;
}

.c-Form-label--inline {
  position: relative;
  padding-left: 45px;
  margin: 0;
}

.c-Form-radio ~ .c-Form-label--inline:before, .c-Form-radio ~ .c-Form-label--inline:after,
.c-Form-checkbox ~ .c-Form-label--inline:before,
.c-Form-checkbox ~ .c-Form-label--inline:after {
  position: absolute;
}

.c-Form-radio ~ .c-Form-label--inline:before,
.c-Form-checkbox ~ .c-Form-label--inline:before {
  content: "";
  top: -11px;
  left: 0;
  width: 38px;
  height: 38px;
  border: 1px solid #d7d7d7;
}

.c-Form-radio ~ .c-Form-label--inline:before, .c-Form-radio ~ .c-Form-label--inline:after {
  border-radius: 50%;
}

.c-Form-radio ~ .c-Form-label--inline:after {
  top: -3px;
  left: 8px;
  width: 22px;
  height: 22px;
  background-color: #666;
}

.c-Form-checkbox ~ .c-Form-label--inline:after {
  top: -2px;
  left: 6px;
  width: 27px;
  height: 21px;
  background: url("../images/icons/check.svg") no-repeat 0 0;
  background-size: 100% 100%;
}

html.no-svg .c-Form-checkbox ~ .c-Form-label--inline:after {
  background-image: url("../images/icons/check.png");
}

.c-Form-radio:checked ~ .c-Form-label--inline:after,
.c-Form-checkbox:checked ~ .c-Form-label--inline:after {
  content: "";
}

@media (min-width: 768px) {
  .c-Form-label--inline {
    padding-left: 60px;
  }
  .c-Form-radio ~ .c-Form-label--inline:before,
  .c-Form-checkbox ~ .c-Form-label--inline:before {
    top: -6px;
  }
  .c-Form-radio ~ .c-Form-label--inline:after {
    top: 2px;
  }
  .c-Form-checkbox ~ .c-Form-label--inline:after {
    top: 3px;
  }
}

.c-Form-label--alt {
  font-size: 12px;
  font-size: 0.75rem;
  margin-left: 0;
  text-align: center;
}

@media (min-width: 768px) {
  .c-Form-label--alt {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.c-Form-input,
.c-Form-textarea {
  width: 100%;
  padding: 10px;
  margin: 0;
  border: 1px solid #d7d7d7;
  background-color: #fff;
}

.c-Form-block {
  position: relative;
  margin-bottom: 30px;
}

.c-Form-labelblock {
  display: block;
  padding: 60px 50% 30px 30px;
  cursor: pointer;
  font-weight: 600;
}

.c-Form-block--sprava .c-Form-labelblock {
  background-color: #f0f0f0;
}

.c-Form-block--aktivity .c-Form-labelblock {
  background-color: #c2e0bf;
}

.c-Form-block--helpdesk .c-Form-labelblock {
  background-color: #f7e3b0;
}

.c-Form-labelblock > span {
  position: relative;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .c-Form-labelblock {
    padding: 30px 30px 100px;
  }
  .c-Form-labelblock > span {
    display: block;
  }
}

.c-Form-blockInput {
  position: absolute;
  bottom: 20px;
  right: 30px;
  width: 45%;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .c-Form-blockInput {
    width: 80%;
    right: 10%;
  }
}

.c-Form-table {
  min-height: .01%;
  margin-bottom: 40px;
  overflow-x: auto;
}

.c-Form-table table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  overflow-x: auto;
}

.c-Form-table table th,
.c-Form-table table td {
  padding: 10px;
  border: 1px solid #d7d7d7;
  text-align: left;
}

.c-Form-table table td {
  vertical-align: top;
}

.c-Form-table table tbody tr:nth-child(odd) td {
  background-color: #f4f4f4;
}

.c-Form-table table tbody tr:hover td {
  background-color: #e9e7e7;
}

@media (min-width: 768px) {
  .c-Form-table {
    margin-bottom: 50px;
  }
  .c-Form-table table {
    table-layout: fixed;
  }
  .c-Form-table table th,
  .c-Form-table table td {
    padding: 15px 20px;
  }
  .c-Form-table table th {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.c-Form-button {
  padding: 10px 15px;
  border: 1px solid #51b048;
  background-color: #51b048;
  cursor: pointer;
}

.c-Form-button > span {
  display: inline-block;
  color: #fff;
  text-decoration: underline;
  vertical-align: middle;
}

.c-Form-button:hover {
  border-color: #333;
  background-color: transparent;
}

.c-Form-button:hover > span {
  color: #333;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-Form-button {
    padding: 20px 30px 20px 25px;
  }
}

@media (min-width: 1220px) {
  .c-Form-button {
    padding: 20px 30px 20px 25px;
  }
}

.c-Form-icon.icon-submit {
  width: 44px;
  height: 38px;
  margin-right: 5px;
  color: #fff;
}

.c-Form-button:hover .c-Form-icon.icon-submit {
  color: #333;
}

.c-Form-icon.icon-check {
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: none;
  width: 44px;
  height: 35px;
  margin-bottom: 5px;
  margin-left: -22px;
}

.c-Form-checkblock:checked ~ .c-Form-labelblock .c-Form-icon.icon-check {
  display: block;
}

.c-Form-block--sprava .c-Form-icon.icon-check {
  color: #33addf;
}

.c-Form-block--aktivity .c-Form-icon.icon-check {
  color: #51b048;
}

.c-Form-block--helpdesk .c-Form-icon.icon-check {
  color: #f59c10;
}

@media (min-width: 768px) {
  .c-Form-icon.icon-submit {
    margin-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .c-Form-icon.icon-check {
    bottom: 50%;
    right: 0;
    left: auto;
    margin-bottom: -17px;
  }
}

/* ==========================================================================
   Components.Wysiwyg
   ========================================================================== */
.c-Wysiwyg h1,
.c-Wysiwyg h2,
.c-Wysiwyg h3,
.c-Wysiwyg h4,
.c-Wysiwyg h5,
.c-Wysiwyg h6 {
  font-weight: 600;
}

.c-Wysiwyg h1 {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.3;
}

.c-Wysiwyg h2 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.3;
}

.c-Wysiwyg h3,
.c-Wysiwyg h4,
.c-Wysiwyg h5,
.c-Wysiwyg h6 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 0;
}

.c-Wysiwyg > ul,
.c-Wysiwyg > ol {
  overflow: hidden;
}

.c-Wysiwyg > ul {
  padding-left: 0;
  list-style: none;
}

.c-Wysiwyg > ul > li {
  position: relative;
  padding-left: 20px;
}

.c-Wysiwyg > ul > li:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #999;
}

.c-Wysiwyg > ol {
  padding-left: 1.5em;
}

.c-Wysiwyg table {
  width: 100%;
  border-collapse: collapse;
}

.c-Wysiwyg table th,
.c-Wysiwyg table td {
  padding: 10px 0;
  border-bottom: 1px solid #c0c0c0;
  text-align: left;
}

.c-Wysiwyg table th {
  padding-right: 20px;
  border-right: 2px solid #c0c0c0;
  white-space: nowrap;
}

.c-Wysiwyg table td {
  padding-left: 20px;
  width: 100%;
}

.c-Wysiwyg table tr.last th,
.c-Wysiwyg table tr.last td {
  border-bottom: none;
}

.c-Wysiwyg table tr.top th,
.c-Wysiwyg table tr.top td,
.c-Wysiwyg table tr.bottom th,
.c-Wysiwyg table tr.bottom td {
  padding: 0;
  border: none;
}

@media (max-width: 767px) {
  .c-Wysiwyg [style="float: left;"],
  .c-Wysiwyg [style="float: right;"] {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .c-Wysiwyg h1 {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.2;
  }
  .c-Wysiwyg h2 {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .c-Wysiwyg [style="float: left;"],
  .c-Wysiwyg [style="float: right;"] {
    max-width: 50%;
    margin-bottom: 40px;
  }
  .c-Wysiwyg [style="float: left;"] {
    margin-right: 40px;
  }
  .c-Wysiwyg [style="float: right;"] {
    margin-left: 40px;
  }
}

@media (min-width: 1220px) {
  .c-Wysiwyg h1 {
    font-size: 48px;
    font-size: 3rem;
  }
  .c-Wysiwyg h2 {
    font-size: 24px;
    font-size: 1.5rem;
  }
  .c-Wysiwyg [style="float: left;"],
  .c-Wysiwyg [style="float: right;"] {
    max-width: none;
  }
}

@media (min-width: 768px) {
  .c-Wysiwyg-downloads {
    float: left;
    width: 340px;
    margin-right: 25px;
  }
}

@media (min-width: 768px) {
  .c-Wysiwyg-buttons {
    float: left;
    margin-right: 25px;
  }
}

/* ==========================================================================
   Components.Gallery
   ========================================================================== */
.c-Gallery {
  margin: 0 -20px;
}

@media (min-width: 768px) {
  .c-Gallery {
    margin: 0 50px;
  }
}

.c-Gallery-item {
  height: auto;
  padding: 0 5px;
  background-color: #cccccc;
  background-clip: content-box;
}

@media (min-width: 768px) {
  .c-Gallery-item {
    padding: 0 10px;
  }
}

.c-Gallery-link {
  text-decoration: none;
}

.c-Gallery-desc {
  display: block;
  padding: 5px 10px;
  color: #000000;
}

@media (min-width: 768px) {
  .c-Gallery-desc {
    padding: 10px 20px;
  }
}

@media (min-width: 1220px) {
  .c-Gallery-desc {
    font-size: 16px;
    font-size: 1rem;
  }
}

.c-Gallery-prev,
.c-Gallery-next {
  position: absolute;
  top: 50%;
  z-index: 5;
  margin-top: -7.5px;
}

@media (min-width: 768px) {
  .c-Gallery-prev,
  .c-Gallery-next {
    margin-top: -7.5px;
  }
}

@media (min-width: 768px) {
  .c-Gallery-prev {
    left: -50px;
  }
}

@media (min-width: 768px) {
  .c-Gallery-next {
    right: -50px;
  }
}

.c-Gallery-icon {
  width: 27px;
  height: 15px;
  color: #666;
  transition: color 0.3s;
}

.c-Carousel-prev:hover .c-Gallery-icon,
.c-Carousel-next:hover .c-Gallery-icon {
  color: #000;
}

/* ==========================================================================
   Components.Downloads
   ========================================================================== */
.c-Downloads {
  padding: 0 10px;
  background-color: #333;
  color: #fff;
  overflow: hidden;
}

@media (min-width: 768px) {
  .c-Downloads {
    padding: 0 20px;
  }
}

.c-Downloads--alpha {
  background-color: #33addf;
}

.c-Downloads--beta {
  background-color: #f59c10;
}

.c-Downloads--gamma {
  background-color: #51b048;
}

.c-Downloads-item {
  margin: 20px 0;
}

.c-Downloads-icon {
  float: left;
  margin-top: 10px;
  margin-right: 10px;
  color: inherit;
}

@media (min-width: 768px) {
  .c-Downloads-icon {
    margin-right: 20px;
  }
}

.c-Downloads-link {
  position: relative;
  top: 8px;
}

/* ==========================================================================
   Components.Buttons
   ========================================================================== */
.c-Buttons {
  font-size: 16px;
  font-size: 1rem;
  font-weight: normal;
}

.c-Buttons-item {
  margin-top: 10px;
}

.c-Buttons-item:first-child {
  margin-top: 0;
}

.c-Buttons-link {
  display: block;
  padding: 0 10px;
  border: 1px solid;
  text-decoration: none;
}

.c-Buttons-link > span {
  display: inline-block;
  padding: 20px 0;
  text-decoration: underline;
  vertical-align: middle;
}

.c-Buttons-link:hover {
  background-color: transparent !important;
  border-color: #333 !important;
}

.c-Buttons-link:hover > span {
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-Buttons-link {
    display: inline-block;
    min-width: 320px;
    padding: 0 15px;
  }
}

@media (min-width: 1220px) {
  .c-Buttons-link {
    min-width: 350px;
    padding: 0 25px;
  }
}

.c-Buttons-icon {
  width: 40px;
  margin-right: 10px;
  color: #fff;
}

.c-Buttons-link:hover .c-Buttons-icon {
  color: #333;
}

.c-Buttons-icon > svg {
  width: auto;
  height: 40px;
}

@media (min-width: 1220px) {
  .c-Buttons-icon {
    margin-right: 20px;
  }
}

.c-Buttons-label {
  color: #fff;
}

.c-Buttons-link:hover .c-Buttons-label {
  color: #333;
}

/* ==========================================================================
   Components.Timeline
   ========================================================================== */
.c-Timeline {
  position: relative;
  margin-bottom: 0;
}

.c-Timeline:before {
  position: absolute;
  top: 0;
  right: 50px;
  width: 1px;
  height: 100%;
  background-color: #ccc;
}

@media (min-width: 480px) {
  .c-Timeline:before {
    content: "";
  }
}

.c-Timeline-year {
  font-size: 24px;
  font-size: 1.5rem;
  position: relative;
  z-index: 5;
  width: 100px;
  padding: 5px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.c-Timeline--alpha .c-Timeline-year {
  background-color: #33addf;
}

.c-Timeline--beta .c-Timeline-year {
  background-color: #f59c10;
}

.c-Timeline--gamma .c-Timeline-year {
  background-color: #51b048;
}

@media (min-width: 480px) {
  .c-Timeline-year {
    float: right;
    clear: right;
    margin-bottom: 50px;
  }
  .c-Timeline-year:last-of-type {
    margin-bottom: 0;
  }
}

.c-Timeline-desc {
  margin-bottom: 20px;
}

.c-Timeline-desc:last-child {
  position: relative;
  margin-bottom: 40px;
}

.c-Timeline-desc:last-child:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 100%;
  background-color: #fff;
}

@media (min-width: 480px) {
  .c-Timeline-desc {
    min-height: 46px;
    padding-right: 120px;
  }
  .c-Timeline-desc:last-child:before {
    content: "";
  }
}

@media (min-width: 768px) {
  .c-Timeline-desc:last-child {
    margin-bottom: 0;
  }
}

/* ==========================================================================
   Components.Excerpt
   ========================================================================== */
.c-Excerpt {
  margin-bottom: 30px;
}

.c-Excerpt:last-child {
  margin-bottom: 0;
}

.c-Excerpt [style="float: left;"] {
  margin-right: 15px;
  margin-bottom: 10px;
}

.c-Excerpt h3 {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.c-Excerpt *:last-child {
  margin-bottom: 0;
}

/* ==========================================================================
   Components.Cells
   ========================================================================== */
.c-Cells {
  overflow: hidden;
}

@media (min-width: 1220px) {
  .c-Cells-inner {
    display: table;
    margin-left: -1px;
  }
  .c-Cells--stretch .c-Cells-inner {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .c-Cells-item {
    display: inline-block;
    vertical-align: top;
    width: 49%;
  }
  .c-Cells-item:nth-child(2n) {
    padding-left: 25px;
  }
}

@media (min-width: 1220px) {
  .c-Cells-item {
    display: table-cell;
    width: auto;
    padding: 40px 30px;
    border-left: 1px solid #ccc;
  }
  .c-Cells-item:nth-child(2n) {
    padding-right: 30px;
  }
  .c-Cells-item:nth-child(4n) {
    padding-right: 0;
  }
  .c-Cells-item:nth-child(4n + 1) {
    padding-left: 0;
  }
  .c-Cells-item > p {
    margin-bottom: 0;
  }
}

/* ==========================================================================
   Components.CTA
   ========================================================================== */
.c-Cta {
  font-size: 16px;
  font-size: 1rem;
  font-weight: normal;
}

.c-Cta--inline {
  text-align: center;
}

.c-Cta-item {
  margin-top: 10px;
}

.c-Cta--inline .c-Cta-item {
  text-align: left;
}

@media (min-width: 768px) {
  .c-Cta--inline .c-Cta-item {
    display: inline-block;
    margin: 0 10px;
  }
}

@media (min-width: 1220px) {
  .c-Cta--inline .c-Cta-item {
    margin: 0 25px;
  }
}

.c-Cta-link {
  display: block;
  padding: 0 10px;
  border-bottom: 1px solid #c0c0c0;
  text-decoration: none;
}

.c-Cta-item--fill .c-Cta-link {
  border: 1px solid;
}

.c-Cta-link > span {
  display: inline-block;
  padding: 20px 0;
  text-decoration: underline;
  vertical-align: middle;
}

.c-Cta--alpha .c-Cta-link:hover {
  border-color: #33addf;
  background-color: #33addf;
}

.c-Cta--beta .c-Cta-link:hover {
  border-color: #f59c10;
  background-color: #f59c10;
}

.c-Cta--gamma .c-Cta-link:hover {
  border-color: #51b048;
  background-color: #51b048;
}

.c-Cta-item--fill .c-Cta-link:hover {
  background-color: transparent !important;
  border-color: #333 !important;
}

.c-Cta-link:hover > span {
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-Cta-link {
    display: inline-block;
    min-width: 320px;
    padding: 0 15px;
  }
}

@media (min-width: 1220px) {
  .c-Cta-link {
    min-width: 350px;
    padding: 0 25px;
  }
}

.c-Cta-icon {
  width: 40px;
  margin-right: 10px;
}

.c-Cta--alpha .c-Cta-icon {
  color: #33addf;
}

.c-Cta--beta .c-Cta-icon {
  color: #f59c10;
}

.c-Cta--gamma .c-Cta-icon {
  color: #51b048;
}

.c-Cta-item--fill .c-Cta-icon {
  color: #fff;
}

.c-Cta-link:hover .c-Cta-icon {
  color: #fff;
}

.c-Cta-item--fill .c-Cta-link:hover .c-Cta-icon {
  color: #333;
}

.c-Cta-icon > svg {
  width: auto;
  height: 40px;
}

@media (min-width: 1220px) {
  .c-Cta-icon {
    margin-right: 20px;
  }
}

.c-Cta-label {
  color: #fff;
}

.c-Cta-link:hover .c-Cta-label {
  color: #333;
}

/* ==========================================================================
   Components.Carousel
   ========================================================================== */
.c-Carousel {
  background-color: #f0f0f0;
}

.c-Carousel-list {
  padding: 20px 0 65px;
}

@media (min-width: 768px) {
  .c-Carousel-list {
    padding: 30px 0 95px;
    margin: 0 -25px;
  }
}

.c-Carousel-item {
  position: relative;
  height: auto;
}

@media (min-width: 768px) {
  .c-Carousel-item {
    padding: 0 25px;
  }
}

.c-Carousel-prev,
.c-Carousel-next {
  position: absolute;
  bottom: 25px;
  z-index: 5;
}

.c-Carousel-prev.slick-disabled,
.c-Carousel-next.slick-disabled {
  display: none !important;
}

@media (min-width: 768px) {
  .c-Carousel-prev,
  .c-Carousel-next {
    bottom: 40px;
  }
}

.c-Carousel-prev {
  right: 50%;
  margin-right: 10px;
}

.c-Carousel-next {
  left: 50%;
  margin-left: 10px;
}

.c-Carousel-icon {
  width: 27px;
  height: 15px;
  color: #666;
  transition: color 0.3s;
}

.c-Carousel-prev:hover .c-Carousel-icon,
.c-Carousel-next:hover .c-Carousel-icon {
  color: #000;
}

/* ==========================================================================
   Components.Post
   ========================================================================== */
.c-Post-date {
  font-size: 12px;
  font-size: 0.75rem;
  margin-bottom: 10px;
  color: #999;
  font-weight: 600;
}

.c-Post-title {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 10px;
  font-weight: normal;
  line-height: 1.3;
}

.c-Post-desc {
  margin-bottom: 10px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .c-Post-desc {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

/* ==========================================================================
   Components.Partners
   ========================================================================== */
.c-Partners {
  text-align: center;
}

.c-Partners-inner {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: left;
}

@media (min-width: 768px) {
  .c-Partners-inner {
    margin-bottom: 50px;
  }
}

.c-Partners-list {
  margin-bottom: -2px;
  margin-right: -2px;
  font-size: 0;
}

.c-Partners-item {
  position: relative;
  display: inline-block;
  width: 33.3333333333%;
  vertical-align: top;
}

.c-Partners-item:before, .c-Partners-item:after {
  content: "";
  position: absolute;
  background-color: #ccc;
}

.c-Partners-item:before {
  bottom: 0;
  left: 5%;
  width: 90%;
  height: 1px;
}

.c-Partners-item:after {
  top: 5%;
  right: 0;
  width: 1px;
  height: 90%;
}

@media (min-width: 480px) {
  .c-Partners-item {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .c-Partners-item {
    width: 20%;
  }
}

.c-Partners-link {
  position: relative;
  display: block;
  height: 0;
  padding-bottom: 100%;
}

.c-Partners-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1220px) {
  .c-Partners-image {
    width: auto;
  }
}

/* ==========================================================================
   Components.Directory
   ========================================================================== */
.c-Directory {
  position: relative;
  text-align: center;
}

@media (min-width: 768px) {
  .c-Directory:before, .c-Directory:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    width: 50%;
    height: 140px;
  }
  .c-Directory:before {
    left: 0;
    background-color: #33addf;
  }
  .c-Directory:after {
    left: 50%;
    background-color: #f59c10;
  }
}

@media (min-width: 768px) {
  .c-Directory-list {
    font-size: 0;
  }
}

.c-Directory-item {
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: 20px;
  line-height: 1.3;
  overflow: hidden;
  vertical-align: top;
}

@media (min-width: 768px) {
  .c-Directory-item {
    display: inline-block;
    width: 33.3333333333%;
    margin-top: 0;
  }
  .c-Directory-item:first-child {
    border-right: 10px solid #fff;
  }
  .c-Directory-item:nth-child(2) {
    border-right: 10px solid #fff;
    border-left: 10px solid #fff;
  }
  .c-Directory-item:last-child {
    border-left: 10px solid #fff;
  }
}

.c-Directory-icon {
  float: left;
  display: block;
  width: 70px;
  height: 70px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% 80%;
}

@media (min-width: 480px) {
  .c-Directory-icon {
    width: 140px;
    height: 140px;
    background-size: auto auto;
  }
}

@media (min-width: 768px) {
  .c-Directory-icon {
    float: none;
    width: 100%;
    height: 140px;
    margin-bottom: 30px;
    background-size: auto auto;
  }
}

.c-Directory-icon--sprava {
  background-color: #33addf;
  background-image: url("../images/icons/sprava.png");
}

@media (min-width: 768px) {
  .c-Directory-icon--sprava {
    background-color: transparent;
  }
}

.c-Directory-icon--aktivity {
  background-color: #51b048;
  background-image: url("../images/icons/aktivity.png");
}

.c-Directory-icon--helpdesk {
  background-color: #f59c10;
  background-image: url("../images/icons/helpdesk.png");
}

@media (min-width: 768px) {
  .c-Directory-icon--helpdesk {
    background-color: transparent;
  }
}

.c-Directory-link {
  display: block;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
}

.c-Directory-link:hover {
  color: #666;
}

@media (min-width: 768px) {
  .c-Directory-link {
    display: inline-block;
  }
}

/* ==========================================================================
   Components.Footer
   ========================================================================== */
.c-Footer {
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .c-Footer {
    position: relative;
    z-index: 15;
    padding-bottom: 0;
  }
}

.c-Footer-up {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  backface-visibility: hidden;
}

/* ==========================================================================
   Components.Map
   ========================================================================== */
.c-Map {
  position: relative;
  color: #333;
  overflow: hidden;
}

@media (min-width: 768px) {
  .c-Map {
    height: 356px;
  }
}

.c-Map-header {
  position: relative;
  left: -100%;
  width: 200%;
  margin-bottom: 20px;
  text-align: right;
}

@media (min-width: 768px) {
  .c-Map-header {
    position: absolute;
    top: 0;
    right: -40%;
    left: auto;
    width: 2000px;
    height: 356px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .c-Map-header {
    right: -20%;
  }
}

@media (min-width: 1220px) {
  .c-Map-header {
    right: 0;
  }
}

@media (min-width: 2000px) {
  .c-Map-header {
    width: 100%;
  }
}

.c-Map-link {
  display: block;
  background-color: #f0f0f0;
}

@media (min-width: 768px) {
  .c-Map-body {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
  }
  .c-Map-body > .o-Container {
    position: relative;
    height: 0;
  }
}

.c-Map-contact strong {
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .c-Map-contact {
    position: absolute;
    transform: translateY(-50%);
  }
}

.c-Map-title {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .c-Map-title {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.2;
  }
}

@media (min-width: 1220px) {
  .c-Map-title {
    font-size: 34px;
    font-size: 2.125rem;
  }
}

/* ==========================================================================
   Components.Up
   ========================================================================== */
.c-Up {
  display: block;
  width: 60px;
  height: 55px;
  padding-top: 14px;
  background-color: #f59c10;
  opacity: 0;
  text-align: center;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
  visibility: hidden;
}

.c-Up:hover {
  background-color: #333;
}

.c-Up.is-visible {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 768px) {
  .c-Up {
    width: 80px;
    height: 70px;
    padding-top: 21.5px;
  }
}

.c-Up-icon {
  width: 15px;
  height: 27px;
  color: #fff;
}

/**
 * Utils
 */
/* ==========================================================================
   Utils.Align
   ========================================================================== */
/**
 * Vertical alignment utilities
 * Depends on an appropriate `display` value.
 */
.u-alignBaseline {
  vertical-align: baseline !important;
}

.u-alignBottom {
  vertical-align: bottom !important;
}

.u-alignMiddle {
  vertical-align: middle !important;
}

.u-alignTop {
  vertical-align: top !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u-alignBaseline\@smallOnly {
    vertical-align: baseline !important;
  }
  .u-alignBottom\@smallOnly {
    vertical-align: bottom !important;
  }
  .u-alignMiddle\@smallOnly {
    vertical-align: middle !important;
  }
  .u-alignTop\@smallOnly {
    vertical-align: top !important;
  }
}

@media (min-width: 480px) {
  .u-alignBaseline\@smallUp {
    vertical-align: baseline !important;
  }
  .u-alignBottom\@smallUp {
    vertical-align: bottom !important;
  }
  .u-alignMiddle\@smallUp {
    vertical-align: middle !important;
  }
  .u-alignTop\@smallUp {
    vertical-align: top !important;
  }
}

@media (max-width: 479px) {
  .u-alignBaseline\@smallDown {
    vertical-align: baseline !important;
  }
  .u-alignBottom\@smallDown {
    vertical-align: bottom !important;
  }
  .u-alignMiddle\@smallDown {
    vertical-align: middle !important;
  }
  .u-alignTop\@smallDown {
    vertical-align: top !important;
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .u-alignBaseline\@mediumOnly {
    vertical-align: baseline !important;
  }
  .u-alignBottom\@mediumOnly {
    vertical-align: bottom !important;
  }
  .u-alignMiddle\@mediumOnly {
    vertical-align: middle !important;
  }
  .u-alignTop\@mediumOnly {
    vertical-align: top !important;
  }
}

@media (min-width: 768px) {
  .u-alignBaseline\@mediumUp {
    vertical-align: baseline !important;
  }
  .u-alignBottom\@mediumUp {
    vertical-align: bottom !important;
  }
  .u-alignMiddle\@mediumUp {
    vertical-align: middle !important;
  }
  .u-alignTop\@mediumUp {
    vertical-align: top !important;
  }
}

@media (max-width: 767px) {
  .u-alignBaseline\@mediumDown {
    vertical-align: baseline !important;
  }
  .u-alignBottom\@mediumDown {
    vertical-align: bottom !important;
  }
  .u-alignMiddle\@mediumDown {
    vertical-align: middle !important;
  }
  .u-alignTop\@mediumDown {
    vertical-align: top !important;
  }
}

@media (min-width: 1220px) {
  .u-alignBaseline\@largeUp {
    vertical-align: baseline !important;
  }
  .u-alignBottom\@largeUp {
    vertical-align: bottom !important;
  }
  .u-alignMiddle\@largeUp {
    vertical-align: middle !important;
  }
  .u-alignTop\@largeUp {
    vertical-align: top !important;
  }
}

@media (max-width: 1219px) {
  .u-alignBaseline\@largeDown {
    vertical-align: baseline !important;
  }
  .u-alignBottom\@largeDown {
    vertical-align: bottom !important;
  }
  .u-alignMiddle\@largeDown {
    vertical-align: middle !important;
  }
  .u-alignTop\@largeDown {
    vertical-align: top !important;
  }
}

/* ==========================================================================
   Utils.Display
   ========================================================================== */
.u-inline {
  display: inline !important;
}

/**
 * 1. Fix for Firefox bug: an image styled `max-width:100%` within an
 * inline-block will display at its default size, and not limit its width to
 * 100% of an ancestral container.
 */
.u-inlineBlock {
  display: inline-block !important;
  max-width: 100%;
  /* 1 */
}

.u-block {
  display: block !important;
}

.u-table {
  display: table !important;
}

.u-tableCell {
  display: table-cell !important;
}

.u-tableRow {
  display: table-row !important;
}

.u-visible {
  visibility: visible !important;
}

.u-invisible {
  visibility: hidden !important;
}

.u-hidden {
  display: none !important;
}

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.u-hiddenVisually {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u-inline\@smallOnly {
    display: inline !important;
  }
  .u-inlineBlock\@smallOnly {
    display: inline-block !important;
    max-width: 100%;
  }
  .u-block\@smallOnly {
    display: block !important;
  }
  .u-table\@smallOnly {
    display: table !important;
  }
  .u-tableCell\@smallOnly {
    display: table-cell !important;
  }
  .u-tableRow\@smallOnly {
    display: table-row !important;
  }
  .u-visible\@smallOnly {
    visibility: visible !important;
  }
  .u-invisible\@smallOnly {
    visibility: hidden !important;
  }
  .u-hidden\@smallOnly {
    display: none !important;
  }
  .u-hiddenVisually\@smallOnly {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }
}

@media (min-width: 480px) {
  .u-inline\@smallUp {
    display: inline !important;
  }
  .u-inlineBlock\@smallUp {
    display: inline-block !important;
    max-width: 100%;
  }
  .u-block\@smallUp {
    display: block !important;
  }
  .u-table\@smallUp {
    display: table !important;
  }
  .u-tableCell\@smallUp {
    display: table-cell !important;
  }
  .u-tableRow\@smallUp {
    display: table-row !important;
  }
  .u-visible\@smallUp {
    visibility: visible !important;
  }
  .u-invisible\@smallUp {
    visibility: hidden !important;
  }
  .u-hidden\@smallUp {
    display: none !important;
  }
  .u-hiddenVisually\@smallUp {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }
}

@media (max-width: 479px) {
  .u-inline\@smallDown {
    display: inline !important;
  }
  .u-inlineBlock\@smallDown {
    display: inline-block !important;
    max-width: 100%;
  }
  .u-block\@smallDown {
    display: block !important;
  }
  .u-table\@smallDown {
    display: table !important;
  }
  .u-tableCell\@smallDown {
    display: table-cell !important;
  }
  .u-tableRow\@smallDown {
    display: table-row !important;
  }
  .u-visible\@smallDown {
    visibility: visible !important;
  }
  .u-invisible\@smallDown {
    visibility: hidden !important;
  }
  .u-hidden\@smallDown {
    display: none !important;
  }
  .u-hiddenVisually\@smallDown {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .u-inline\@mediumOnly {
    display: inline !important;
  }
  .u-inlineBlock\@mediumOnly {
    display: inline-block !important;
    max-width: 100%;
  }
  .u-block\@mediumOnly {
    display: block !important;
  }
  .u-table\@mediumOnly {
    display: table !important;
  }
  .u-tableCell\@mediumOnly {
    display: table-cell !important;
  }
  .u-tableRow\@mediumOnly {
    display: table-row !important;
  }
  .u-visible\@mediumOnly {
    visibility: visible !important;
  }
  .u-invisible\@mediumOnly {
    visibility: hidden !important;
  }
  .u-hidden\@mediumOnly {
    display: none !important;
  }
  .u-hiddenVisually\@mediumOnly {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }
}

@media (min-width: 768px) {
  .u-inline\@mediumUp {
    display: inline !important;
  }
  .u-inlineBlock\@mediumUp {
    display: inline-block !important;
    max-width: 100%;
  }
  .u-block\@mediumUp {
    display: block !important;
  }
  .u-table\@mediumUp {
    display: table !important;
  }
  .u-tableCell\@mediumUp {
    display: table-cell !important;
  }
  .u-tableRow\@mediumUp {
    display: table-row !important;
  }
  .u-visible\@mediumUp {
    visibility: visible !important;
  }
  .u-invisible\@mediumUp {
    visibility: hidden !important;
  }
  .u-hidden\@mediumUp {
    display: none !important;
  }
  .u-hiddenVisually\@mediumUp {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }
}

@media (max-width: 767px) {
  .u-inline\@mediumDown {
    display: inline !important;
  }
  .u-inlineBlock\@mediumDown {
    display: inline-block !important;
    max-width: 100%;
  }
  .u-block\@mediumDown {
    display: block !important;
  }
  .u-table\@mediumDown {
    display: table !important;
  }
  .u-tableCell\@mediumDown {
    display: table-cell !important;
  }
  .u-tableRow\@mediumDown {
    display: table-row !important;
  }
  .u-visible\@mediumDown {
    visibility: visible !important;
  }
  .u-invisible\@mediumDown {
    visibility: hidden !important;
  }
  .u-hidden\@mediumDown {
    display: none !important;
  }
  .u-hiddenVisually\@mediumDown {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }
}

@media (min-width: 1220px) {
  .u-inline\@largeUp {
    display: inline !important;
  }
  .u-inlineBlock\@largeUp {
    display: inline-block !important;
    max-width: 100%;
  }
  .u-block\@largeUp {
    display: block !important;
  }
  .u-table\@largeUp {
    display: table !important;
  }
  .u-tableCell\@largeUp {
    display: table-cell !important;
  }
  .u-tableRow\@largeUp {
    display: table-row !important;
  }
  .u-visible\@largeUp {
    visibility: visible !important;
  }
  .u-invisible\@largeUp {
    visibility: hidden !important;
  }
  .u-hidden\@largeUp {
    display: none !important;
  }
  .u-hiddenVisually\@largeUp {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }
}

@media (max-width: 1219px) {
  .u-inline\@largeDown {
    display: inline !important;
  }
  .u-inlineBlock\@largeDown {
    display: inline-block !important;
    max-width: 100%;
  }
  .u-block\@largeDown {
    display: block !important;
  }
  .u-table\@largeDown {
    display: table !important;
  }
  .u-tableCell\@largeDown {
    display: table-cell !important;
  }
  .u-tableRow\@largeDown {
    display: table-row !important;
  }
  .u-visible\@largeDown {
    visibility: visible !important;
  }
  .u-invisible\@largeDown {
    visibility: hidden !important;
  }
  .u-hidden\@largeDown {
    display: none !important;
  }
  .u-hiddenVisually\@largeDown {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }
}

/* ==========================================================================
   Utils.Layout
   ========================================================================== */
/**
 * Contain floats
 *
 * Make an element expand to contain floated children.
 * Uses pseudo-elements (micro clearfix).
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of the
 *    element.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.u-cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
  clear: both;
}

/**
 * New block formatting context
 *
 * This affords some useful properties to the element. It won't wrap under
 * floats. Will also contain any floated children.

 * N.B. This will clip overflow. Use the alternative method below if this is
 * problematic.
 */
.u-nbfc {
  overflow: hidden !important;
}

/**
 * New block formatting context (alternative)
 *
 * Alternative method when overflow must not be clipped.
 *
 * 1. Create a new block formatting context (NBFC).
 * 2. Avoid shrink-wrap behaviour of table-cell.
 *
 * N.B. This breaks down in some browsers when elements within this element
 * exceed its width.
 */
.u-nbfcAlt {
  display: table-cell !important;
  /* 1 */
  width: 10000px !important;
  /* 2 */
}

/**
 * Floats
 */
.u-floatLeft {
  float: left !important;
}

.u-floatRight {
  float: right !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u-cf:after\@smallOnly {
    content: " ";
    display: table;
    clear: both;
  }
  .u-nbfc\@smallOnly {
    overflow: hidden !important;
  }
  .u-nbfcAlt\@smallOnly {
    display: table-cell !important;
    width: 10000px !important;
  }
  .u-floatLeft\@smallOnly {
    float: left !important;
  }
  .u-floatRight\@smallOnly {
    float: right !important;
  }
}

@media (min-width: 480px) {
  .u-cf:after\@smallUp {
    content: " ";
    display: table;
    clear: both;
  }
  .u-nbfc\@smallUp {
    overflow: hidden !important;
  }
  .u-nbfcAlt\@smallUp {
    display: table-cell !important;
    width: 10000px !important;
  }
  .u-floatLeft\@smallUp {
    float: left !important;
  }
  .u-floatRight\@smallUp {
    float: right !important;
  }
}

@media (max-width: 479px) {
  .u-cf:after\@smallDown {
    content: " ";
    display: table;
    clear: both;
  }
  .u-nbfc\@smallDown {
    overflow: hidden !important;
  }
  .u-nbfcAlt\@smallDown {
    display: table-cell !important;
    width: 10000px !important;
  }
  .u-floatLeft\@smallDown {
    float: left !important;
  }
  .u-floatRight\@smallDown {
    float: right !important;
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .u-cf:after\@mediumOnly {
    content: " ";
    display: table;
    clear: both;
  }
  .u-nbfc\@mediumOnly {
    overflow: hidden !important;
  }
  .u-nbfcAlt\@mediumOnly {
    display: table-cell !important;
    width: 10000px !important;
  }
  .u-floatLeft\@mediumOnly {
    float: left !important;
  }
  .u-floatRight\@mediumOnly {
    float: right !important;
  }
}

@media (min-width: 768px) {
  .u-cf:after\@mediumUp {
    content: " ";
    display: table;
    clear: both;
  }
  .u-nbfc\@mediumUp {
    overflow: hidden !important;
  }
  .u-nbfcAlt\@mediumUp {
    display: table-cell !important;
    width: 10000px !important;
  }
  .u-floatLeft\@mediumUp {
    float: left !important;
  }
  .u-floatRight\@mediumUp {
    float: right !important;
  }
}

@media (max-width: 767px) {
  .u-cf:after\@mediumDown {
    content: " ";
    display: table;
    clear: both;
  }
  .u-nbfc\@mediumDown {
    overflow: hidden !important;
  }
  .u-nbfcAlt\@mediumDown {
    display: table-cell !important;
    width: 10000px !important;
  }
  .u-floatLeft\@mediumDown {
    float: left !important;
  }
  .u-floatRight\@mediumDown {
    float: right !important;
  }
}

@media (min-width: 1220px) {
  .u-cf:after\@largeUp {
    content: " ";
    display: table;
    clear: both;
  }
  .u-nbfc\@largeUp {
    overflow: hidden !important;
  }
  .u-nbfcAlt\@largeUp {
    display: table-cell !important;
    width: 10000px !important;
  }
  .u-floatLeft\@largeUp {
    float: left !important;
  }
  .u-floatRight\@largeUp {
    float: right !important;
  }
}

@media (max-width: 1219px) {
  .u-cf:after\@largeDown {
    content: " ";
    display: table;
    clear: both;
  }
  .u-nbfc\@largeDown {
    overflow: hidden !important;
  }
  .u-nbfcAlt\@largeDown {
    display: table-cell !important;
    width: 10000px !important;
  }
  .u-floatLeft\@largeDown {
    float: left !important;
  }
  .u-floatRight\@largeDown {
    float: right !important;
  }
}

/* ==========================================================================
   Utils.Position
   ========================================================================== */
.u-posAbsolute {
  position: absolute !important;
}

/**
 * Pins to all corners by default. But when a width and/or height are
 * provided, the element will be centered in its nearest relatively-positioned
 * ancestor.
 */
.u-posAbsoluteCenter {
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}

/**
 * 1. Make sure fixed elements are promoted into a new layer, for performance
 *    reasons.
 */
.u-posFixed {
  position: fixed !important;
  backface-visibility: hidden;
  /* 1 */
}

.u-posRelative {
  position: relative !important;
}

.u-posStatic {
  position: static !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u-posAbsolute\@smallOnly {
    position: absolute !important;
  }
  .u-posAbsoluteCenter\@smallOnly {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .u-posFixed\@smallOnly {
    position: fixed !important;
    backface-visibility: hidden;
    /* 1 */
  }
  .u-posRelative\@smallOnly {
    position: relative !important;
  }
  .u-posStatic\@smallOnly {
    position: static !important;
  }
}

@media (min-width: 480px) {
  .u-posAbsolute\@smallUp {
    position: absolute !important;
  }
  .u-posAbsoluteCenter\@smallUp {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .u-posFixed\@smallUp {
    position: fixed !important;
    backface-visibility: hidden;
    /* 1 */
  }
  .u-posRelative\@smallUp {
    position: relative !important;
  }
  .u-posStatic\@smallUp {
    position: static !important;
  }
}

@media (max-width: 479px) {
  .u-posAbsolute\@smallDown {
    position: absolute !important;
  }
  .u-posAbsoluteCenter\@smallDown {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .u-posFixed\@smallDown {
    position: fixed !important;
    backface-visibility: hidden;
    /* 1 */
  }
  .u-posRelative\@smallDown {
    position: relative !important;
  }
  .u-posStatic\@smallDown {
    position: static !important;
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .u-posAbsolute\@mediumOnly {
    position: absolute !important;
  }
  .u-posAbsoluteCenter\@mediumOnly {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .u-posFixed\@mediumOnly {
    position: fixed !important;
    backface-visibility: hidden;
    /* 1 */
  }
  .u-posRelative\@mediumOnly {
    position: relative !important;
  }
  .u-posStatic\@mediumOnly {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .u-posAbsolute\@mediumUp {
    position: absolute !important;
  }
  .u-posAbsoluteCenter\@mediumUp {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .u-posFixed\@mediumUp {
    position: fixed !important;
    backface-visibility: hidden;
    /* 1 */
  }
  .u-posRelative\@mediumUp {
    position: relative !important;
  }
  .u-posStatic\@mediumUp {
    position: static !important;
  }
}

@media (max-width: 767px) {
  .u-posAbsolute\@mediumDown {
    position: absolute !important;
  }
  .u-posAbsoluteCenter\@mediumDown {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .u-posFixed\@mediumDown {
    position: fixed !important;
    backface-visibility: hidden;
    /* 1 */
  }
  .u-posRelative\@mediumDown {
    position: relative !important;
  }
  .u-posStatic\@mediumDown {
    position: static !important;
  }
}

@media (min-width: 1220px) {
  .u-posAbsolute\@largeUp {
    position: absolute !important;
  }
  .u-posAbsoluteCenter\@largeUp {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .u-posFixed\@largeUp {
    position: fixed !important;
    backface-visibility: hidden;
    /* 1 */
  }
  .u-posRelative\@largeUp {
    position: relative !important;
  }
  .u-posStatic\@largeUp {
    position: static !important;
  }
}

@media (max-width: 1219px) {
  .u-posAbsolute\@largeDown {
    position: absolute !important;
  }
  .u-posAbsoluteCenter\@largeDown {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
  .u-posFixed\@largeDown {
    position: fixed !important;
    backface-visibility: hidden;
    /* 1 */
  }
  .u-posRelative\@largeDown {
    position: relative !important;
  }
  .u-posStatic\@largeDown {
    position: static !important;
  }
}

/* ==========================================================================
   Utils.Size
   ========================================================================== */
/* Intrinsic widths
   ========================================================================== */
/**
 * Make an element shrink wrap its content.
 */
.u-sizeFit,
.u-sizeFitAlt {
  display: block !important;
  float: left !important;
  width: auto !important;
}

.u-sizeFitAlt {
  float: right !important;
}

/**
 * Make an element fill the remaining space.
 * N.B. This will hide overflow.
 */
.u-sizeFill {
  display: block !important;
  overflow: hidden !important;
  width: auto !important;
}

/**
 * An alternative method to make an element fill the remaining space.
 * N.B. Do not use if child elements might be wider than the remaining space.
 * In Chrome, Safari, and Firefox it results in undesired layout.
 */
.u-sizeFillAlt {
  display: table-cell !important;
  max-width: 100% !important;
  width: 10000px !important;
}

/**
 * Make an element the width of its parent.
 */
.u-sizeFull {
  box-sizing: border-box !important;
  display: block !important;
  width: 100% !important;
}

.u-size1of1 {
  width: 100% !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u-sizeFit\@smallOnly,
  .u-sizeFitAlt\@smallOnly {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sizeFitAlt\@smallOnly {
    float: right !important;
  }
  .u-sizeFill\@smallOnly {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .u-sizeFillAlt\@smallOnly {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  .u-sizeFull\@smallOnly {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
  .u-size1of1\@smallOnly {
    width: 100% !important;
  }
}

@media (min-width: 480px) {
  .u-sizeFit\@smallUp,
  .u-sizeFitAlt\@smallUp {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sizeFitAlt\@smallUp {
    float: right !important;
  }
  .u-sizeFill\@smallUp {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .u-sizeFillAlt\@smallUp {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  .u-sizeFull\@smallUp {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
  .u-size1of1\@smallUp {
    width: 100% !important;
  }
}

@media (max-width: 479px) {
  .u-sizeFit\@smallDown,
  .u-sizeFitAlt\@smallDown {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sizeFitAlt\@smallDown {
    float: right !important;
  }
  .u-sizeFill\@smallDown {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .u-sizeFillAlt\@smallDown {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  .u-sizeFull\@smallDown {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
  .u-size1of1\@smallDown {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .u-sizeFit\@mediumOnly,
  .u-sizeFitAlt\@mediumOnly {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sizeFitAlt\@mediumOnly {
    float: right !important;
  }
  .u-sizeFill\@mediumOnly {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .u-sizeFillAlt\@mediumOnly {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  .u-sizeFull\@mediumOnly {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
  .u-size1of1\@mediumOnly {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .u-sizeFit\@mediumUp,
  .u-sizeFitAlt\@mediumUp {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sizeFitAlt\@mediumUp {
    float: right !important;
  }
  .u-sizeFill\@mediumUp {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .u-sizeFillAlt\@mediumUp {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  .u-sizeFull\@mediumUp {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
  .u-size1of1\@mediumUp {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .u-sizeFit\@mediumDown,
  .u-sizeFitAlt\@mediumDown {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sizeFitAlt\@mediumDown {
    float: right !important;
  }
  .u-sizeFill\@mediumDown {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .u-sizeFillAlt\@mediumDown {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  .u-sizeFull\@mediumDown {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
  .u-size1of1\@mediumDown {
    width: 100% !important;
  }
}

@media (min-width: 1220px) {
  .u-sizeFit\@largeUp,
  .u-sizeFitAlt\@largeUp {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sizeFitAlt\@largeUp {
    float: right !important;
  }
  .u-sizeFill\@largeUp {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .u-sizeFillAlt\@largeUp {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  .u-sizeFull\@largeUp {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
  .u-size1of1\@largeUp {
    width: 100% !important;
  }
}

@media (max-width: 1219px) {
  .u-sizeFit\@largeDown,
  .u-sizeFitAlt\@largeDown {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
  .u-sizeFitAlt\@largeDown {
    float: right !important;
  }
  .u-sizeFill\@largeDown {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .u-sizeFillAlt\@largeDown {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
  .u-sizeFull\@largeDown {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
  .u-size1of1\@largeDown {
    width: 100% !important;
  }
}

/* Proportional widths
   ========================================================================== */
.u-size1of2 {
  width: 50% !important;
}

.u-size2of2 {
  width: 100% !important;
}

.u-size1of3 {
  width: 33.3333333333% !important;
}

.u-size2of3 {
  width: 66.6666666667% !important;
}

.u-size3of3 {
  width: 100% !important;
}

.u-size1of4 {
  width: 25% !important;
}

.u-size2of4 {
  width: 50% !important;
}

.u-size3of4 {
  width: 75% !important;
}

.u-size4of4 {
  width: 100% !important;
}

.u-size1of5 {
  width: 20% !important;
}

.u-size2of5 {
  width: 40% !important;
}

.u-size3of5 {
  width: 60% !important;
}

.u-size4of5 {
  width: 80% !important;
}

.u-size5of5 {
  width: 100% !important;
}

.u-size1of8 {
  width: 12.5% !important;
}

.u-size2of8 {
  width: 25% !important;
}

.u-size3of8 {
  width: 37.5% !important;
}

.u-size4of8 {
  width: 50% !important;
}

.u-size5of8 {
  width: 62.5% !important;
}

.u-size6of8 {
  width: 75% !important;
}

.u-size7of8 {
  width: 87.5% !important;
}

.u-size8of8 {
  width: 100% !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u-size1of2\@smallOnly {
    width: 50% !important;
  }
  .u-size2of2\@smallOnly {
    width: 100% !important;
  }
  .u-size1of3\@smallOnly {
    width: 33.3333333333% !important;
  }
  .u-size2of3\@smallOnly {
    width: 66.6666666667% !important;
  }
  .u-size3of3\@smallOnly {
    width: 100% !important;
  }
  .u-size1of4\@smallOnly {
    width: 25% !important;
  }
  .u-size2of4\@smallOnly {
    width: 50% !important;
  }
  .u-size3of4\@smallOnly {
    width: 75% !important;
  }
  .u-size4of4\@smallOnly {
    width: 100% !important;
  }
  .u-size1of5\@smallOnly {
    width: 20% !important;
  }
  .u-size2of5\@smallOnly {
    width: 40% !important;
  }
  .u-size3of5\@smallOnly {
    width: 60% !important;
  }
  .u-size4of5\@smallOnly {
    width: 80% !important;
  }
  .u-size5of5\@smallOnly {
    width: 100% !important;
  }
  .u-size1of8\@smallOnly {
    width: 12.5% !important;
  }
  .u-size2of8\@smallOnly {
    width: 25% !important;
  }
  .u-size3of8\@smallOnly {
    width: 37.5% !important;
  }
  .u-size4of8\@smallOnly {
    width: 50% !important;
  }
  .u-size5of8\@smallOnly {
    width: 62.5% !important;
  }
  .u-size6of8\@smallOnly {
    width: 75% !important;
  }
  .u-size7of8\@smallOnly {
    width: 87.5% !important;
  }
  .u-size8of8\@smallOnly {
    width: 100% !important;
  }
}

@media (min-width: 480px) {
  .u-size1of2\@smallUp {
    width: 50% !important;
  }
  .u-size2of2\@smallUp {
    width: 100% !important;
  }
  .u-size1of3\@smallUp {
    width: 33.3333333333% !important;
  }
  .u-size2of3\@smallUp {
    width: 66.6666666667% !important;
  }
  .u-size3of3\@smallUp {
    width: 100% !important;
  }
  .u-size1of4\@smallUp {
    width: 25% !important;
  }
  .u-size2of4\@smallUp {
    width: 50% !important;
  }
  .u-size3of4\@smallUp {
    width: 75% !important;
  }
  .u-size4of4\@smallUp {
    width: 100% !important;
  }
  .u-size1of5\@smallUp {
    width: 20% !important;
  }
  .u-size2of5\@smallUp {
    width: 40% !important;
  }
  .u-size3of5\@smallUp {
    width: 60% !important;
  }
  .u-size4of5\@smallUp {
    width: 80% !important;
  }
  .u-size5of5\@smallUp {
    width: 100% !important;
  }
  .u-size1of8\@smallUp {
    width: 12.5% !important;
  }
  .u-size2of8\@smallUp {
    width: 25% !important;
  }
  .u-size3of8\@smallUp {
    width: 37.5% !important;
  }
  .u-size4of8\@smallUp {
    width: 50% !important;
  }
  .u-size5of8\@smallUp {
    width: 62.5% !important;
  }
  .u-size6of8\@smallUp {
    width: 75% !important;
  }
  .u-size7of8\@smallUp {
    width: 87.5% !important;
  }
  .u-size8of8\@smallUp {
    width: 100% !important;
  }
}

@media (max-width: 479px) {
  .u-size1of2\@smallDown {
    width: 50% !important;
  }
  .u-size2of2\@smallDown {
    width: 100% !important;
  }
  .u-size1of3\@smallDown {
    width: 33.3333333333% !important;
  }
  .u-size2of3\@smallDown {
    width: 66.6666666667% !important;
  }
  .u-size3of3\@smallDown {
    width: 100% !important;
  }
  .u-size1of4\@smallDown {
    width: 25% !important;
  }
  .u-size2of4\@smallDown {
    width: 50% !important;
  }
  .u-size3of4\@smallDown {
    width: 75% !important;
  }
  .u-size4of4\@smallDown {
    width: 100% !important;
  }
  .u-size1of5\@smallDown {
    width: 20% !important;
  }
  .u-size2of5\@smallDown {
    width: 40% !important;
  }
  .u-size3of5\@smallDown {
    width: 60% !important;
  }
  .u-size4of5\@smallDown {
    width: 80% !important;
  }
  .u-size5of5\@smallDown {
    width: 100% !important;
  }
  .u-size1of8\@smallDown {
    width: 12.5% !important;
  }
  .u-size2of8\@smallDown {
    width: 25% !important;
  }
  .u-size3of8\@smallDown {
    width: 37.5% !important;
  }
  .u-size4of8\@smallDown {
    width: 50% !important;
  }
  .u-size5of8\@smallDown {
    width: 62.5% !important;
  }
  .u-size6of8\@smallDown {
    width: 75% !important;
  }
  .u-size7of8\@smallDown {
    width: 87.5% !important;
  }
  .u-size8of8\@smallDown {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .u-size1of2\@mediumOnly {
    width: 50% !important;
  }
  .u-size2of2\@mediumOnly {
    width: 100% !important;
  }
  .u-size1of3\@mediumOnly {
    width: 33.3333333333% !important;
  }
  .u-size2of3\@mediumOnly {
    width: 66.6666666667% !important;
  }
  .u-size3of3\@mediumOnly {
    width: 100% !important;
  }
  .u-size1of4\@mediumOnly {
    width: 25% !important;
  }
  .u-size2of4\@mediumOnly {
    width: 50% !important;
  }
  .u-size3of4\@mediumOnly {
    width: 75% !important;
  }
  .u-size4of4\@mediumOnly {
    width: 100% !important;
  }
  .u-size1of5\@mediumOnly {
    width: 20% !important;
  }
  .u-size2of5\@mediumOnly {
    width: 40% !important;
  }
  .u-size3of5\@mediumOnly {
    width: 60% !important;
  }
  .u-size4of5\@mediumOnly {
    width: 80% !important;
  }
  .u-size5of5\@mediumOnly {
    width: 100% !important;
  }
  .u-size1of8\@mediumOnly {
    width: 12.5% !important;
  }
  .u-size2of8\@mediumOnly {
    width: 25% !important;
  }
  .u-size3of8\@mediumOnly {
    width: 37.5% !important;
  }
  .u-size4of8\@mediumOnly {
    width: 50% !important;
  }
  .u-size5of8\@mediumOnly {
    width: 62.5% !important;
  }
  .u-size6of8\@mediumOnly {
    width: 75% !important;
  }
  .u-size7of8\@mediumOnly {
    width: 87.5% !important;
  }
  .u-size8of8\@mediumOnly {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .u-size1of2\@mediumUp {
    width: 50% !important;
  }
  .u-size2of2\@mediumUp {
    width: 100% !important;
  }
  .u-size1of3\@mediumUp {
    width: 33.3333333333% !important;
  }
  .u-size2of3\@mediumUp {
    width: 66.6666666667% !important;
  }
  .u-size3of3\@mediumUp {
    width: 100% !important;
  }
  .u-size1of4\@mediumUp {
    width: 25% !important;
  }
  .u-size2of4\@mediumUp {
    width: 50% !important;
  }
  .u-size3of4\@mediumUp {
    width: 75% !important;
  }
  .u-size4of4\@mediumUp {
    width: 100% !important;
  }
  .u-size1of5\@mediumUp {
    width: 20% !important;
  }
  .u-size2of5\@mediumUp {
    width: 40% !important;
  }
  .u-size3of5\@mediumUp {
    width: 60% !important;
  }
  .u-size4of5\@mediumUp {
    width: 80% !important;
  }
  .u-size5of5\@mediumUp {
    width: 100% !important;
  }
  .u-size1of8\@mediumUp {
    width: 12.5% !important;
  }
  .u-size2of8\@mediumUp {
    width: 25% !important;
  }
  .u-size3of8\@mediumUp {
    width: 37.5% !important;
  }
  .u-size4of8\@mediumUp {
    width: 50% !important;
  }
  .u-size5of8\@mediumUp {
    width: 62.5% !important;
  }
  .u-size6of8\@mediumUp {
    width: 75% !important;
  }
  .u-size7of8\@mediumUp {
    width: 87.5% !important;
  }
  .u-size8of8\@mediumUp {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .u-size1of2\@mediumDown {
    width: 50% !important;
  }
  .u-size2of2\@mediumDown {
    width: 100% !important;
  }
  .u-size1of3\@mediumDown {
    width: 33.3333333333% !important;
  }
  .u-size2of3\@mediumDown {
    width: 66.6666666667% !important;
  }
  .u-size3of3\@mediumDown {
    width: 100% !important;
  }
  .u-size1of4\@mediumDown {
    width: 25% !important;
  }
  .u-size2of4\@mediumDown {
    width: 50% !important;
  }
  .u-size3of4\@mediumDown {
    width: 75% !important;
  }
  .u-size4of4\@mediumDown {
    width: 100% !important;
  }
  .u-size1of5\@mediumDown {
    width: 20% !important;
  }
  .u-size2of5\@mediumDown {
    width: 40% !important;
  }
  .u-size3of5\@mediumDown {
    width: 60% !important;
  }
  .u-size4of5\@mediumDown {
    width: 80% !important;
  }
  .u-size5of5\@mediumDown {
    width: 100% !important;
  }
  .u-size1of8\@mediumDown {
    width: 12.5% !important;
  }
  .u-size2of8\@mediumDown {
    width: 25% !important;
  }
  .u-size3of8\@mediumDown {
    width: 37.5% !important;
  }
  .u-size4of8\@mediumDown {
    width: 50% !important;
  }
  .u-size5of8\@mediumDown {
    width: 62.5% !important;
  }
  .u-size6of8\@mediumDown {
    width: 75% !important;
  }
  .u-size7of8\@mediumDown {
    width: 87.5% !important;
  }
  .u-size8of8\@mediumDown {
    width: 100% !important;
  }
}

@media (min-width: 1220px) {
  .u-size1of2\@largeUp {
    width: 50% !important;
  }
  .u-size2of2\@largeUp {
    width: 100% !important;
  }
  .u-size1of3\@largeUp {
    width: 33.3333333333% !important;
  }
  .u-size2of3\@largeUp {
    width: 66.6666666667% !important;
  }
  .u-size3of3\@largeUp {
    width: 100% !important;
  }
  .u-size1of4\@largeUp {
    width: 25% !important;
  }
  .u-size2of4\@largeUp {
    width: 50% !important;
  }
  .u-size3of4\@largeUp {
    width: 75% !important;
  }
  .u-size4of4\@largeUp {
    width: 100% !important;
  }
  .u-size1of5\@largeUp {
    width: 20% !important;
  }
  .u-size2of5\@largeUp {
    width: 40% !important;
  }
  .u-size3of5\@largeUp {
    width: 60% !important;
  }
  .u-size4of5\@largeUp {
    width: 80% !important;
  }
  .u-size5of5\@largeUp {
    width: 100% !important;
  }
  .u-size1of8\@largeUp {
    width: 12.5% !important;
  }
  .u-size2of8\@largeUp {
    width: 25% !important;
  }
  .u-size3of8\@largeUp {
    width: 37.5% !important;
  }
  .u-size4of8\@largeUp {
    width: 50% !important;
  }
  .u-size5of8\@largeUp {
    width: 62.5% !important;
  }
  .u-size6of8\@largeUp {
    width: 75% !important;
  }
  .u-size7of8\@largeUp {
    width: 87.5% !important;
  }
  .u-size8of8\@largeUp {
    width: 100% !important;
  }
}

@media (max-width: 1219px) {
  .u-size1of2\@largeDown {
    width: 50% !important;
  }
  .u-size2of2\@largeDown {
    width: 100% !important;
  }
  .u-size1of3\@largeDown {
    width: 33.3333333333% !important;
  }
  .u-size2of3\@largeDown {
    width: 66.6666666667% !important;
  }
  .u-size3of3\@largeDown {
    width: 100% !important;
  }
  .u-size1of4\@largeDown {
    width: 25% !important;
  }
  .u-size2of4\@largeDown {
    width: 50% !important;
  }
  .u-size3of4\@largeDown {
    width: 75% !important;
  }
  .u-size4of4\@largeDown {
    width: 100% !important;
  }
  .u-size1of5\@largeDown {
    width: 20% !important;
  }
  .u-size2of5\@largeDown {
    width: 40% !important;
  }
  .u-size3of5\@largeDown {
    width: 60% !important;
  }
  .u-size4of5\@largeDown {
    width: 80% !important;
  }
  .u-size5of5\@largeDown {
    width: 100% !important;
  }
  .u-size1of8\@largeDown {
    width: 12.5% !important;
  }
  .u-size2of8\@largeDown {
    width: 25% !important;
  }
  .u-size3of8\@largeDown {
    width: 37.5% !important;
  }
  .u-size4of8\@largeDown {
    width: 50% !important;
  }
  .u-size5of8\@largeDown {
    width: 62.5% !important;
  }
  .u-size6of8\@largeDown {
    width: 75% !important;
  }
  .u-size7of8\@largeDown {
    width: 87.5% !important;
  }
  .u-size8of8\@largeDown {
    width: 100% !important;
  }
}

/* ==========================================================================
   Utils.Space
   ========================================================================== */
/**
 * Reset all spaces
 */
.u-paddingA {
  padding: 0 !important;
}

.u-marginA {
  margin: 0 !important;
}

/**
 * Reset vertical spaces
 */
.u-paddingV {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-marginV {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/**
 * Reset horizontal spaces
 */
.u-paddingH {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.u-marginH {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/**
 * Reset top space
 */
.u-paddingT {
  padding-top: 0 !important;
}

.u-marginT {
  margin-top: 0 !important;
}

/**
 * Reset right space
 */
.u-paddingR {
  padding-right: 0 !important;
}

.u-marginR {
  margin-right: 0 !important;
}

/**
 * Reset bottom space
 */
.u-paddingB {
  padding-bottom: 0 !important;
}

.u-marginB {
  margin-bottom: 0 !important;
}

/**
 * Reset left space
 */
.u-paddingL {
  padding-left: 0 !important;
}

.u-marginL {
  margin-left: 0 !important;
}

@media (min-width: 480px) and (max-width: 767px) {
  .u-paddingA\@smallOnly {
    padding: 0 !important;
  }
  .u-marginA\@smallOnly {
    margin: 0 !important;
  }
  .u-paddingV\@smallOnly {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-marginV\@smallOnly {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .u-paddingH\@smallOnly {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .u-marginH\@smallOnly {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .u-paddingT\@smallOnly {
    padding-top: 0 !important;
  }
  .u-marginT\@smallOnly {
    margin-top: 0 !important;
  }
  .u-paddingR\@smallOnly {
    padding-right: 0 !important;
  }
  .u-marginR\@smallOnly {
    margin-right: 0 !important;
  }
  .u-paddingB\@smallOnly {
    padding-bottom: 0 !important;
  }
  .u-marginB\@smallOnly {
    margin-bottom: 0 !important;
  }
  .u-paddingL\@smallOnly {
    padding-left: 0 !important;
  }
  .u-marginL\@smallOnly {
    margin-left: 0 !important;
  }
}

@media (min-width: 480px) {
  .u-paddingA\@smallUp {
    padding: 0 !important;
  }
  .u-marginA\@smallUp {
    margin: 0 !important;
  }
  .u-paddingV\@smallUp {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-marginV\@smallUp {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .u-paddingH\@smallUp {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .u-marginH\@smallUp {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .u-paddingT\@smallUp {
    padding-top: 0 !important;
  }
  .u-marginT\@smallUp {
    margin-top: 0 !important;
  }
  .u-paddingR\@smallUp {
    padding-right: 0 !important;
  }
  .u-marginR\@smallUp {
    margin-right: 0 !important;
  }
  .u-paddingB\@smallUp {
    padding-bottom: 0 !important;
  }
  .u-marginB\@smallUp {
    margin-bottom: 0 !important;
  }
  .u-paddingL\@smallUp {
    padding-left: 0 !important;
  }
  .u-marginL\@smallUp {
    margin-left: 0 !important;
  }
}

@media (max-width: 479px) {
  .u-paddingA\@smallDown {
    padding: 0 !important;
  }
  .u-marginA\@smallDown {
    margin: 0 !important;
  }
  .u-paddingV\@smallDown {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-marginV\@smallDown {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .u-paddingH\@smallDown {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .u-marginH\@smallDown {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .u-paddingT\@smallDown {
    padding-top: 0 !important;
  }
  .u-marginT\@smallDown {
    margin-top: 0 !important;
  }
  .u-paddingR\@smallDown {
    padding-right: 0 !important;
  }
  .u-marginR\@smallDown {
    margin-right: 0 !important;
  }
  .u-paddingB\@smallDown {
    padding-bottom: 0 !important;
  }
  .u-marginB\@smallDown {
    margin-bottom: 0 !important;
  }
  .u-paddingL\@smallDown {
    padding-left: 0 !important;
  }
  .u-marginL\@smallDown {
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .u-paddingA\@mediumOnly {
    padding: 0 !important;
  }
  .u-marginA\@mediumOnly {
    margin: 0 !important;
  }
  .u-paddingV\@mediumOnly {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-marginV\@mediumOnly {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .u-paddingH\@mediumOnly {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .u-marginH\@mediumOnly {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .u-paddingT\@mediumOnly {
    padding-top: 0 !important;
  }
  .u-marginT\@mediumOnly {
    margin-top: 0 !important;
  }
  .u-paddingR\@mediumOnly {
    padding-right: 0 !important;
  }
  .u-marginR\@mediumOnly {
    margin-right: 0 !important;
  }
  .u-paddingB\@mediumOnly {
    padding-bottom: 0 !important;
  }
  .u-marginB\@mediumOnly {
    margin-bottom: 0 !important;
  }
  .u-paddingL\@mediumOnly {
    padding-left: 0 !important;
  }
  .u-marginL\@mediumOnly {
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .u-paddingA\@mediumUp {
    padding: 0 !important;
  }
  .u-marginA\@mediumUp {
    margin: 0 !important;
  }
  .u-paddingV\@mediumUp {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-marginV\@mediumUp {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .u-paddingH\@mediumUp {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .u-marginH\@mediumUp {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .u-paddingT\@mediumUp {
    padding-top: 0 !important;
  }
  .u-marginT\@mediumUp {
    margin-top: 0 !important;
  }
  .u-paddingR\@mediumUp {
    padding-right: 0 !important;
  }
  .u-marginR\@mediumUp {
    margin-right: 0 !important;
  }
  .u-paddingB\@mediumUp {
    padding-bottom: 0 !important;
  }
  .u-marginB\@mediumUp {
    margin-bottom: 0 !important;
  }
  .u-paddingL\@mediumUp {
    padding-left: 0 !important;
  }
  .u-marginL\@mediumUp {
    margin-left: 0 !important;
  }
}

@media (max-width: 767px) {
  .u-paddingA\@mediumDown {
    padding: 0 !important;
  }
  .u-marginA\@mediumDown {
    margin: 0 !important;
  }
  .u-paddingV\@mediumDown {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-marginV\@mediumDown {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .u-paddingH\@mediumDown {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .u-marginH\@mediumDown {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .u-paddingT\@mediumDown {
    padding-top: 0 !important;
  }
  .u-marginT\@mediumDown {
    margin-top: 0 !important;
  }
  .u-paddingR\@mediumDown {
    padding-right: 0 !important;
  }
  .u-marginR\@mediumDown {
    margin-right: 0 !important;
  }
  .u-paddingB\@mediumDown {
    padding-bottom: 0 !important;
  }
  .u-marginB\@mediumDown {
    margin-bottom: 0 !important;
  }
  .u-paddingL\@mediumDown {
    padding-left: 0 !important;
  }
  .u-marginL\@mediumDown {
    margin-left: 0 !important;
  }
}

@media (min-width: 1220px) {
  .u-paddingA\@largeUp {
    padding: 0 !important;
  }
  .u-marginA\@largeUp {
    margin: 0 !important;
  }
  .u-paddingV\@largeUp {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-marginV\@largeUp {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .u-paddingH\@largeUp {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .u-marginH\@largeUp {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .u-paddingT\@largeUp {
    padding-top: 0 !important;
  }
  .u-marginT\@largeUp {
    margin-top: 0 !important;
  }
  .u-paddingR\@largeUp {
    padding-right: 0 !important;
  }
  .u-marginR\@largeUp {
    margin-right: 0 !important;
  }
  .u-paddingB\@largeUp {
    padding-bottom: 0 !important;
  }
  .u-marginB\@largeUp {
    margin-bottom: 0 !important;
  }
  .u-paddingL\@largeUp {
    padding-left: 0 !important;
  }
  .u-marginL\@largeUp {
    margin-left: 0 !important;
  }
}

@media (max-width: 1219px) {
  .u-paddingA\@largeDown {
    padding: 0 !important;
  }
  .u-marginA\@largeDown {
    margin: 0 !important;
  }
  .u-paddingV\@largeDown {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-marginV\@largeDown {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .u-paddingH\@largeDown {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .u-marginH\@largeDown {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .u-paddingT\@largeDown {
    padding-top: 0 !important;
  }
  .u-marginT\@largeDown {
    margin-top: 0 !important;
  }
  .u-paddingR\@largeDown {
    padding-right: 0 !important;
  }
  .u-marginR\@largeDown {
    margin-right: 0 !important;
  }
  .u-paddingB\@largeDown {
    padding-bottom: 0 !important;
  }
  .u-marginB\@largeDown {
    margin-bottom: 0 !important;
  }
  .u-paddingL\@largeDown {
    padding-left: 0 !important;
  }
  .u-marginL\@largeDown {
    margin-left: 0 !important;
  }
}

/* ==========================================================================
   Utils.Text
   ========================================================================== */
/**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */
.u-textBreak {
  word-wrap: break-word !important;
}

/**
 * Horizontal text alignment
 */
.u-textCenter {
  text-align: center !important;
}

.u-textLeft {
  text-align: left !important;
}

.u-textRight {
  text-align: right !important;
}

/**
 * Inherit the ancestor's text color.
 */
.u-textInheritColor {
  color: inherit !important;
}

/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 * 2. Chrome (not Windows), Firefox, IE 10+
 * 3. Safari 7 and future browsers
 */
.u-textKern {
  text-rendering: optimizeLegibility;
  /* 1 */
  font-feature-settings: "kern" 1;
  /* 2 */
  font-kerning: normal;
  /* 3 */
}

/**
 * Prevent whitespace wrapping
 */
.u-textNoWrap {
  white-space: nowrap !important;
}

/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
.u-textTruncate {
  max-width: 100%;
  /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  /* 2 */
}

@media (min-width: 480px) and (max-width: 767px) {
  .u-textBreak\@smallOnly {
    word-wrap: break-word !important;
  }
  .u-textCenter\@smallOnly {
    text-align: center !important;
  }
  .u-textLeft\@smallOnly {
    text-align: left !important;
  }
  .u-textRight\@smallOnly {
    text-align: right !important;
  }
  .u-textInheritColor\@smallOnly {
    color: inherit !important;
  }
  .u-textKern\@smallOnly {
    text-rendering: optimizeLegibility;
    /* 1 */
    font-feature-settings: "kern" 1;
    /* 2 */
    font-kerning: normal;
    /* 3 */
  }
  .u-textNoWrap\@smallOnly {
    white-space: nowrap !important;
  }
  .u-textTruncate\@smallOnly {
    max-width: 100%;
    /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    /* 2 */
  }
}

@media (min-width: 480px) {
  .u-textBreak\@smallUp {
    word-wrap: break-word !important;
  }
  .u-textCenter\@smallUp {
    text-align: center !important;
  }
  .u-textLeft\@smallUp {
    text-align: left !important;
  }
  .u-textRight\@smallUp {
    text-align: right !important;
  }
  .u-textInheritColor\@smallUp {
    color: inherit !important;
  }
  .u-textKern\@smallUp {
    text-rendering: optimizeLegibility;
    /* 1 */
    font-feature-settings: "kern" 1;
    /* 2 */
    font-kerning: normal;
    /* 3 */
  }
  .u-textNoWrap\@smallUp {
    white-space: nowrap !important;
  }
  .u-textTruncate\@smallUp {
    max-width: 100%;
    /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    /* 2 */
  }
}

@media (max-width: 479px) {
  .u-textBreak\@smallDown {
    word-wrap: break-word !important;
  }
  .u-textCenter\@smallDown {
    text-align: center !important;
  }
  .u-textLeft\@smallDown {
    text-align: left !important;
  }
  .u-textRight\@smallDown {
    text-align: right !important;
  }
  .u-textInheritColor\@smallDown {
    color: inherit !important;
  }
  .u-textKern\@smallDown {
    text-rendering: optimizeLegibility;
    /* 1 */
    font-feature-settings: "kern" 1;
    /* 2 */
    font-kerning: normal;
    /* 3 */
  }
  .u-textNoWrap\@smallDown {
    white-space: nowrap !important;
  }
  .u-textTruncate\@smallDown {
    max-width: 100%;
    /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    /* 2 */
  }
}

@media (min-width: 768px) and (max-width: 1219px) {
  .u-textBreak\@mediumOnly {
    word-wrap: break-word !important;
  }
  .u-textCenter\@mediumOnly {
    text-align: center !important;
  }
  .u-textLeft\@mediumOnly {
    text-align: left !important;
  }
  .u-textRight\@mediumOnly {
    text-align: right !important;
  }
  .u-textInheritColor\@mediumOnly {
    color: inherit !important;
  }
  .u-textKern\@mediumOnly {
    text-rendering: optimizeLegibility;
    /* 1 */
    font-feature-settings: "kern" 1;
    /* 2 */
    font-kerning: normal;
    /* 3 */
  }
  .u-textNoWrap\@mediumOnly {
    white-space: nowrap !important;
  }
  .u-textTruncate\@mediumOnly {
    max-width: 100%;
    /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    /* 2 */
  }
}

@media (min-width: 768px) {
  .u-textBreak\@mediumUp {
    word-wrap: break-word !important;
  }
  .u-textCenter\@mediumUp {
    text-align: center !important;
  }
  .u-textLeft\@mediumUp {
    text-align: left !important;
  }
  .u-textRight\@mediumUp {
    text-align: right !important;
  }
  .u-textInheritColor\@mediumUp {
    color: inherit !important;
  }
  .u-textKern\@mediumUp {
    text-rendering: optimizeLegibility;
    /* 1 */
    font-feature-settings: "kern" 1;
    /* 2 */
    font-kerning: normal;
    /* 3 */
  }
  .u-textNoWrap\@mediumUp {
    white-space: nowrap !important;
  }
  .u-textTruncate\@mediumUp {
    max-width: 100%;
    /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    /* 2 */
  }
}

@media (max-width: 767px) {
  .u-textBreak\@mediumDown {
    word-wrap: break-word !important;
  }
  .u-textCenter\@mediumDown {
    text-align: center !important;
  }
  .u-textLeft\@mediumDown {
    text-align: left !important;
  }
  .u-textRight\@mediumDown {
    text-align: right !important;
  }
  .u-textInheritColor\@mediumDown {
    color: inherit !important;
  }
  .u-textKern\@mediumDown {
    text-rendering: optimizeLegibility;
    /* 1 */
    font-feature-settings: "kern" 1;
    /* 2 */
    font-kerning: normal;
    /* 3 */
  }
  .u-textNoWrap\@mediumDown {
    white-space: nowrap !important;
  }
  .u-textTruncate\@mediumDown {
    max-width: 100%;
    /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    /* 2 */
  }
}

@media (min-width: 1220px) {
  .u-textBreak\@largeUp {
    word-wrap: break-word !important;
  }
  .u-textCenter\@largeUp {
    text-align: center !important;
  }
  .u-textLeft\@largeUp {
    text-align: left !important;
  }
  .u-textRight\@largeUp {
    text-align: right !important;
  }
  .u-textInheritColor\@largeUp {
    color: inherit !important;
  }
  .u-textKern\@largeUp {
    text-rendering: optimizeLegibility;
    /* 1 */
    font-feature-settings: "kern" 1;
    /* 2 */
    font-kerning: normal;
    /* 3 */
  }
  .u-textNoWrap\@largeUp {
    white-space: nowrap !important;
  }
  .u-textTruncate\@largeUp {
    max-width: 100%;
    /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    /* 2 */
  }
}

@media (max-width: 1219px) {
  .u-textBreak\@largeDown {
    word-wrap: break-word !important;
  }
  .u-textCenter\@largeDown {
    text-align: center !important;
  }
  .u-textLeft\@largeDown {
    text-align: left !important;
  }
  .u-textRight\@largeDown {
    text-align: right !important;
  }
  .u-textInheritColor\@largeDown {
    color: inherit !important;
  }
  .u-textKern\@largeDown {
    text-rendering: optimizeLegibility;
    /* 1 */
    font-feature-settings: "kern" 1;
    /* 2 */
    font-kerning: normal;
    /* 3 */
  }
  .u-textNoWrap\@largeDown {
    white-space: nowrap !important;
  }
  .u-textTruncate\@largeDown {
    max-width: 100%;
    /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    /* 2 */
  }
}
