/* ==========================================================================
   Objects.Icon
   ========================================================================== */

.o-Icon {
    display: inline-block;
    background: none;
    vertical-align: middle;

    > svg {
        width: inherit;
        height: inherit;
        fill: currentColor;
        vertical-align: top;
    }
}
