/* ==========================================================================
   Components.Wysiwyg
   ========================================================================== */

.c-Wysiwyg {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
    }

    h1 {
        @include rem(font-size, 28px);
        line-height: 1.3;
    }

    h2 {
        @include rem(font-size, 20px);
        line-height: 1.3;
    }

    h3,
    h4,
    h5,
    h6 {
        @include rem(font-size, 18px);
        margin-bottom: 0;
    }

    > ul,
    > ol {
        overflow: hidden;
    }

    > ul {
        padding-left: 0;
        list-style: none;

        > li {
            position: relative;
            padding-left: 20px;

            &:before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #999;
            }
        }
    }

    > ol {
        padding-left: 1.5em;
    }

    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            padding: 10px 0;
            border-bottom: 1px solid #c0c0c0;
            text-align: left;
        }

        th {
            padding-right: 20px;
            border-right: 2px solid #c0c0c0;
            white-space: nowrap;
        }

        td {
            padding-left: 20px;
            width: 100%;
        }

        tr.last {
            th,
            td {
                border-bottom: none;
            }
        }

        tr.top,
        tr.bottom {
            th,
            td {
                padding: 0;
                border: none;
            }
        }
    }

    @include media("<medium") {
        [style="float: left;"],
        [style="float: right;"] {
            float: none !important;
        }
    }

    @include media(">=medium") {
        h1 {
            @include rem(font-size, 36px);
            line-height: 1.2;
        }

        h2 {
            @include rem(font-size, 22px);
        }

        [style="float: left;"],
        [style="float: right;"] {
            max-width: 50%;
            margin-bottom: double($Base-unitV);
        }

        [style="float: left;"] {
            margin-right: 40px;
        }

        [style="float: right;"] {
            margin-left: 40px;
        }
    }

    @include media(">=large") {
        h1 {
            @include rem(font-size, 48px);
        }

        h2 {
            @include rem(font-size, 24px);
        }

        [style="float: left;"],
        [style="float: right;"] {
            max-width: none;
        }
    }
}

// .c-Wysiwyg--alpha {
//     h1 {
//         color: $Base-colorAlpha;
//     }

//     > ul {
//         > li {
//             &:before {
//                 background-color: $Base-colorAlpha;
//             }
//         }
//     }
// }

// .c-Wysiwyg--beta {
//     h1 {
//         color: $Base-colorBeta;
//     }

//     > ul {
//         > li {
//             &:before {
//                 background-color: $Base-colorBeta;
//             }
//         }
//     }
// }

// .c-Wysiwyg--gamma {
//     h1 {
//         color: $Base-colorGamma;
//     }

//     > ul {
//         > li {
//             &:before {
//                 background-color: $Base-colorGamma;
//             }
//         }
//     }
// }

.c-Wysiwyg-downloads {
    @include media(">=medium") {
        float: left;
        width: 340px;
        margin-right: 25px;
    }
}

.c-Wysiwyg-buttons {
    @include media(">=medium") {
        float: left;
        margin-right: 25px;
    }
}
