/* ==========================================================================
   Objects.Button
   ========================================================================== */

$Button-padding:          quarter($Base-unitV) $Base-unitH;
$Button-borderColor:      currentcolor;
$Button-borderWidth:      1px;
$Button-color:            inherit;
$Button-font:             inherit;
$Button-disabledOpacity:  0.6;



.o-Button {
    position: relative;
    display: inline-block;
    padding: $Button-padding;
    margin: 0;
    border-color: $Button-borderColor;
    border-style: solid;
    border-width: $Button-borderWidth;
    background: transparent;
    color: $Button-color;
    cursor: pointer;
    font: $Button-font;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        cursor: default;
        opacity: $Button-disabledOpacity
    }
}

.o-Button--stretch {
    width: 100%;
}

.o-ButtonBare {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    background: transparent;
    color: inherit;
    font: inherit;
    text-align: inherit;
    text-decoration: none;
    user-select: none;
    vertical-align: baseline;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}
