/* ==========================================================================
   Components.Bar
   ========================================================================== */

.c-Bar {
    @include rem(font-size, 14px);
    padding: 5px 0;
    background-color: #f0544f;
    color: #fff;
    font-weight: bold;
    text-align: center;

    @include media(">=medium") {
        @include rem(font-size, 18px);
    }
}

.c-Bar--muted {
    @include rem(font-size, 11px);
    background-color:#f4f4f4;
    color:#666;
    font-weight: normal;
}

.c-Bar-desc {
    margin-bottom: 0;
}
