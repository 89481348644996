/* ==========================================================================
   Components.Cells
   ========================================================================== */

.c-Cells {
    overflow: hidden;
}

.c-Cells-inner {
    @include media(">=large") {
        display: table;
        margin-left: -1px;

        .c-Cells--stretch & {
            width: 100%;
        }
    }
}

.c-Cells-item {
    @include media(">=medium") {
        display: inline-block;
        vertical-align: top;
        width: 49%;
        // min-height: 30px + (5 * 14px * 1.5) + 30px;

        &:nth-child(2n) {
            padding-left: $Grid-gutter;
        }
    }

    @include media(">=large") {
        display: table-cell;
        width: auto;
        padding: 40px 30px;
        border-left: 1px solid #ccc;

        &:nth-child(2n) {
            padding-right: 30px;
        }

        &:nth-child(4n) {
            padding-right: 0;
        }

        &:nth-child(4n + 1) {
            padding-left: 0;
        }

        > p {
            margin-bottom: 0;
        }
    }
}
