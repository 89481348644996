/* ==========================================================================
   Components.Downloads
   ========================================================================== */

.c-Downloads {
    padding: 0 10px;
    background-color: #333;
    color: #fff;
    overflow: hidden;

    @include media(">=medium") {
        padding: 0 20px;
    }
}

.c-Downloads--alpha {
    background-color: $Base-colorAlpha;
}

.c-Downloads--beta {
    background-color: $Base-colorBeta;
}

.c-Downloads--gamma {
    background-color: $Base-colorGamma;
}

.c-Downloads-item {
    margin: 20px 0;
}

.c-Downloads-icon {
    float: left;
    margin-top: 10px;
    margin-right: 10px;
    color: inherit;

    @include media(">=medium") {
        margin-right: 20px;
    }
}

.c-Downloads-link {
    position: relative;
    top: 8px;
}
