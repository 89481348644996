/* ==========================================================================
   Components.Footer
   ========================================================================== */

.c-Footer {
    padding-bottom: 20px;

    @include media(">=medium") {
        position: relative;
        z-index: 15;
        padding-bottom: 0;
    }
}

.c-Footer-up {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 100;
    backface-visibility: hidden;
}
