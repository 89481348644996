/* ==========================================================================
   Components.Post
   ========================================================================== */

.c-Post-date {
    @include rem(font-size, 12px);
    margin-bottom: halve($Base-unitV);
    color: #999;
    font-weight: 600;
}

.c-Post-title {
    @include rem(font-size, 18px);
    margin-bottom: halve($Base-unitV);
    font-weight: normal;
    line-height: 1.3;
}

.c-Post-desc {
    margin-bottom: halve($Base-unitV);
    margin-bottom: 0;

    @include media(">=medium") {
        @include rem(font-size, 14px);
    }
}
