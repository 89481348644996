/* ==========================================================================
   Components.Content
   ========================================================================== */

.c-Content-section,
.c-Content-landing {
    padding: 20px 0;

    @include media(">=medium") {
        padding: 50px 0;
    }
}

.c-Content-section--zero {
    padding: 0;
}

.c-Content-section--collapse {
    padding-top: 0;
}

.c-Content-section--shrink {
    margin-bottom: -20px;

    @include media(">=medium") {
        margin-bottom: -50px;
    }
}

.c-Content-section--background {
    background-color: #f0f0f0;
}

.c-Content-section--cells {
    @include media(">=medium") {
        padding-bottom: 50px - $Base-unitV;
    }

    @include media(">=large") {
        padding-bottom: 50px;
    }
}

.c-Content-title {
    @include rem(font-size, 28px);
    font-weight: 600;
    line-height: 1.3;

    @include media(">=medium") {
        @include rem(font-size, 36px);
        line-height: 1.2;
    }

    @include media(">=large") {
        @include rem(font-size, 42px);
    }
}

.c-Content-title--product {
    @include media(">=large") {
        @include rem(font-size, 36px);
    }
}

.c-Content-subtitle {
    @include rem(font-size, 20px);
    line-height: 1.3;

    @include media(">=medium") {
        @include rem(font-size, 24px);
        line-height: 1.2;
    }

    @include media(">=large") {
        @include rem(font-size, 36px);
    }
}

.c-Content-subtitle--smaller {
    @include media(">=medium") {
        @include rem(font-size, 20px);
        line-height: 1.2;
    }

    @include media(">=large") {
        @include rem(font-size, 24px);
    }
}

.c-Content-gallery {
    margin-bottom: 20px;
}

.c-Content-video {
    margin-bottom: 20px;
}

.c-Content-Cta {
    margin-top: 40px;

    @include media(">=medium") {
        margin-top: 100px;
    }
}

.c-Content-Cta--hero {
    @include media(">=medium") {
        margin-top: 40px;
    }
}

.c-Content-Cta--zero {
    margin-top: 0;
}

.c-Content-Cta--arrow {
    margin-top: 0;

    &:before {
        content: "";
        display: block;
        width: 200px;
        height: 217px;
        margin: 0 auto 20px;
        margin: 0 auto;
        background: url('../images/icons/arrow.cta.png') no-repeat left bottom;
    }

    @include media(">=medium") {
        // &:before {
        //     height: 217px;
        //     margin-bottom: 50px;
        // }
    }
}


@include media(">=medium") {
    .c-Content-section--collapse\@mediumUp {
        padding-top: 0;
    }
}
