/* ==========================================================================
   Utils.Text
   ========================================================================== */

/**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */

.u-textBreak {
    word-wrap: break-word !important;
}

/**
 * Horizontal text alignment
 */

.u-textCenter {
    text-align: center !important;
}

.u-textLeft {
    text-align: left !important;
}

.u-textRight {
    text-align: right !important;
}

/**
 * Inherit the ancestor's text color.
 */

.u-textInheritColor {
    color: inherit !important;
}

/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 * 2. Chrome (not Windows), Firefox, IE 10+
 * 3. Safari 7 and future browsers
 */

.u-textKern {
    text-rendering: optimizeLegibility; /* 1 */
    font-feature-settings: "kern" 1; /* 2 */
    font-kerning: normal; /* 3 */
}

/**
 * Prevent whitespace wrapping
 */

.u-textNoWrap {
    white-space: nowrap !important;
}

/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */

.u-textTruncate {
    max-width: 100%; /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important; /* 2 */
}



@for $i from 1 through $breakpointsLength {
    $breakpoint: nth($breakpoints, $i);
    $breakpointName: nth($breakpoint, 1);

    @if $i < $breakpointsLength {
        $breakpointNext: nth($breakpoints, $i + 1);
        $breakpointNameNext: nth($breakpointNext, 1);

        @include media(#{'>=' + $breakpointName}, #{'<' + $breakpointNameNext}) {
            .u-textBreak\@#{$breakpointName}Only {
                word-wrap: break-word !important;
            }

            .u-textCenter\@#{$breakpointName}Only {
                text-align: center !important;
            }

            .u-textLeft\@#{$breakpointName}Only {
                text-align: left !important;
            }

            .u-textRight\@#{$breakpointName}Only {
                text-align: right !important;
            }

            .u-textInheritColor\@#{$breakpointName}Only {
                color: inherit !important;
            }

            .u-textKern\@#{$breakpointName}Only {
                text-rendering: optimizeLegibility; /* 1 */
                font-feature-settings: "kern" 1; /* 2 */
                font-kerning: normal; /* 3 */
            }

            .u-textNoWrap\@#{$breakpointName}Only {
                white-space: nowrap !important;
            }

            .u-textTruncate\@#{$breakpointName}Only {
                max-width: 100%; /* 1 */
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                word-wrap: normal !important; /* 2 */
            }
        }
    }

    @include media(#{'>=' + $breakpointName}) {
        .u-textBreak\@#{$breakpointName}Up {
            word-wrap: break-word !important;
        }

        .u-textCenter\@#{$breakpointName}Up {
            text-align: center !important;
        }

        .u-textLeft\@#{$breakpointName}Up {
            text-align: left !important;
        }

        .u-textRight\@#{$breakpointName}Up {
            text-align: right !important;
        }

        .u-textInheritColor\@#{$breakpointName}Up {
            color: inherit !important;
        }

        .u-textKern\@#{$breakpointName}Up {
            text-rendering: optimizeLegibility; /* 1 */
            font-feature-settings: "kern" 1; /* 2 */
            font-kerning: normal; /* 3 */
        }

        .u-textNoWrap\@#{$breakpointName}Up {
            white-space: nowrap !important;
        }

        .u-textTruncate\@#{$breakpointName}Up {
            max-width: 100%; /* 1 */
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            word-wrap: normal !important; /* 2 */
        }
    }

    @include media(#{'<' + $breakpointName}) {
        .u-textBreak\@#{$breakpointName}Down {
            word-wrap: break-word !important;
        }

        .u-textCenter\@#{$breakpointName}Down {
            text-align: center !important;
        }

        .u-textLeft\@#{$breakpointName}Down {
            text-align: left !important;
        }

        .u-textRight\@#{$breakpointName}Down {
            text-align: right !important;
        }

        .u-textInheritColor\@#{$breakpointName}Down {
            color: inherit !important;
        }

        .u-textKern\@#{$breakpointName}Down {
            text-rendering: optimizeLegibility; /* 1 */
            font-feature-settings: "kern" 1; /* 2 */
            font-kerning: normal; /* 3 */
        }

        .u-textNoWrap\@#{$breakpointName}Down {
            white-space: nowrap !important;
        }

        .u-textTruncate\@#{$breakpointName}Down {
            max-width: 100%; /* 1 */
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            word-wrap: normal !important; /* 2 */
        }
    }
}
