/* ==========================================================================
   Generic.Shared
   ========================================================================== */

/**
 * Where `margin-bottom` is concerned,this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
blockquote,
dl,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
ul,
table {
    margin-bottom: $Base-unitV;
}



/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
img,
svg,
video {
    vertical-align: middle;
}



/**
 * Same `line-height` for basic form elements.
 */

button,
input {
    line-height: $Base-lineHeight / $Base-fontSize;
}



/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}



/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex="-1"]:focus {
    outline: none !important;
}
