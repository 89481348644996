/* ==========================================================================
   Components.Breadcrumbs
   ========================================================================== */

.c-Breadcrumbs {
    padding: 20px 0 10px;
    color: #ccc;

    @include media(">=medium") {
        padding: 40px 0 30px;
    }
}

.c-Breadcrumbs-item + .c-Breadcrumbs-item {
    &:before {
        content: "\2013 ";
        display: inline-block;
    }
}
