/* ==========================================================================
   Components.Excerpt
   ========================================================================== */

.c-Excerpt {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    [style="float: left;"] {
        margin-right: 15px;
        margin-bottom: halve($Base-unitV);
    }

    h3 {
        @include rem(font-size, 14px);
        margin-bottom: 0;
        font-weight: bold;
        text-transform: uppercase;
    }

    *:last-child {
        margin-bottom: 0;
    }
}
