/* ==========================================================================
   Components.Slider
   ========================================================================== */

.c-Slider {
    position: relative;
    padding-bottom: 54px;
}

.c-Slider-item {
    height: auto;
    padding: 20px 0;
    background-position: left center;
    background-repeat: no-repeat;

    @include media(">=medium") {
        padding: 0;
        background-size: 45% auto;
    }

    @include media(">=large") {
        background-position: 10% center;
    }

    @include media("<medium") {
        background: transparent !important;
    }
}

.c-Slider-inner {
    @include media(">=medium") {
        position: relative;
        height: 0;
        padding-bottom: percentage(460 / 1180);
    }
}

.c-Slider-body {
    padding-left: 1px;

    @include media(">=medium") {
        position: absolute;
        top: 50%;
        right: 0;
        width: 50%;
        padding-left: 0;
        transform: translateY(-50%);
    }

    @include media(">=large") {
        width: percentage(460 / 1180);
    }
}

.c-Slider-label {
    width: 100%;
    height: 90px;
    margin-bottom: 10px;
    margin-left: -20px;

    @include media("<large") {
        height: 60px;
        margin-left: -10px;
    }
}

.c-Slider-title {
    @include rem(font-size, 22px);
    min-height: 3 * 22px * 1.2;
    font-weight: 600;
    line-height: 1.2;

    > strong {
        color: $Base-colorAlpha;
        font-weight: inherit;
    }

    @include media(">=small") {
        min-height: 2 * 22px * 1.2;
    }

    @include media(">=medium") {
        @include rem(font-size, 28px);
    }

    @include media(">=large") {
        @include rem(font-size, 35px);
        line-height: 1;
    }
}

.c-Slider-dots {
    position: absolute;
    bottom: -34px;
    left: 50%;
    list-style: none;
    padding: 0;
    margin: 0;
    transform: translateX(-50%);

    > li {
        display: inline-block;

        > button {
            width: 14px;
            height: 14px;
            padding: 0;
            margin: 0 5px;
            border: none;
            border-radius: 50%;
            background: #999;
            cursor: pointer;
            font-size: 0;

            &:focus {
                outline: 0;
            }
        }
    }

    > li.slick-active {
        > button {
            background-color: #000;
        }
    }
}
