/* ==========================================================================
   Base.Typography
   ========================================================================== */

/**
 * Basic links behavior.
 */

a {
    color: $Base-colorLinks;
}

a:hover,
a:active {
    text-decoration: none;
}

a:focus {
    outline: 0;
}



/**
 * Basic strong weight.
 */

b,
strong {
    font-weight: $Base-strongWeight;
}



/**
 * Give a help cursor to elements that give extra info on `:hover`.
 */

abbr[title],
dfn[title] {
    cursor: help;
}
