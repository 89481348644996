/* ==========================================================================
   Components.Support
   ========================================================================== */

.c-Support {
    @include rem(font-size, 16px);
    color: #cc0000;
    font-weight: 600;

    @include media(">=medium") {
        @include rem(font-size, 20px);
        text-align: center;
    }

    @include media(">=large") {
        @include rem(font-size, 24px);
    }
}

.c-Support-item {
    position: relative;
    margin-top: 20px;
    padding-left: 75px;

    &:first-child {
        margin-top: 0;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #cc0000;
    }

    @include media(">=medium") {
        display: inline-block;
        padding-left: 135px;
        margin: 0 40px;
        text-align: left;

        &:before {
            width: 120px;
            height: 120px;
        }
    }
}

.c-Support-icon {
    position: absolute;
    color: #fff;

    &.icon-user {
        top: (60px - 30px) / 2;
        left: (60px - 30px) / 2;
        width: 30px;
        height: 30px;
    }

    &.icon-hands {
        top: (60px - 30px) / 2;
        left: (60px - 24px) / 2;
        width: 24px;
        height: 30px;
    }

    @include media(">=medium") {
        &.icon-user {
            top: (120px - 75px) / 2;
            left: (120px - 75px) / 2;
            width: 75px;
            height: 75px;
        }

        &.icon-hands {
            top: (120px - 75px) / 2;
            left: (120px - 60px) / 2;
            width: 60px;
            height: 75px;
        }
    }
}

.c-Support-link {
    display: inline-block;
    margin: ((60px - (2 * 16px * 1.5)) / 2) 0;

    @include media(">=medium") {
        margin: ((120px - (2 * 20px * 1.5)) / 2) 0;
    }

    @include media(">=large") {
        margin: ((120px - (2 * 24px * 1.5)) / 2) 0;
    }
}
