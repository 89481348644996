/* ==========================================================================
   Components.Timeline
   ========================================================================== */

.c-Timeline {
    position: relative;
    margin-bottom: 0;

    &:before {
        position: absolute;
        top: 0;
        right: 50px;
        width: 1px;
        height: 100%;
        background-color: #ccc;
    }

    @include media(">=small") {
        &:before {
            content: "";
        }
    }
}

.c-Timeline-year {
    @include rem(font-size, 24px);
    position: relative;
    z-index: 5;
    width: 100px;
    padding: 5px;
    margin-bottom: 10px;
    color: #fff;
    font-weight: bold;
    text-align: center;

    .c-Timeline--alpha & {
        background-color: $Base-colorAlpha;
    }

    .c-Timeline--beta & {
        background-color: $Base-colorBeta;
    }

    .c-Timeline--gamma & {
        background-color: $Base-colorGamma;
    }

    @include media(">=small") {
        float: right;
        clear: right;
        margin-bottom: 50px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.c-Timeline-desc {
    margin-bottom: 20px;

    &:last-child {
        position: relative;
        margin-bottom: double($Base-unitV);

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            width: 120px;
            height: 100%;
            background-color: $Base-colorBackground;
        }
    }

    @include media(">=small") {
        min-height: 46px;
        padding-right: 120px;

        &:last-child {
            &:before {
                content: "";
            }
        }
    }

    @include media(">=medium") {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
