/* ==========================================================================
   Main
   ========================================================================== */

/**
 * Settings
 */

@import "settings/defaults";



/**
 * Tools
 */

@import "tools/functions";
@import "tools/mixins";

@import "bower_components/include-media/dist/include-media";
@import "bower_components/include-media-export/dist/include-media-export";



/**
 * Generic
 */

@import "generic/reset";
@import "generic/shared";
@import "generic/box-sizing";



/**
 * Base
 */

@import "base/fonts";
@import "base/page";
@import "base/typography";
@import "base/images";



/**
 * Objects
 */

// @import "objects/animations";
@import "objects/button";
@import "objects/color";
@import "objects/container";
@import "objects/embed";
@import "objects/grid";
@import "objects/icon";
@import "objects/list";
@import "objects/size";



/**
 * Libs
 */

@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "bower_components/slick-carousel/slick/slick.scss";
// @import "bower_components/magnific-popup/dist/magnific-popup";



/**
 * Components
 */

@import "components/button";

@import "components/page";
@import "components/bar";
@import "components/header";
@import "components/logo";
@import "components/connect";
@import "components/hamburger";
@import "components/collapse";
@import "components/menu";
@import "components/content";
@import "components/breadcrumbs";
@import "components/slider";
@import "components/hero";
@import "components/support";
@import "components/form";
@import "components/wysiwyg";
@import "components/gallery";
@import "components/downloads";
@import "components/buttons";
@import "components/timeline";
@import "components/excerpt";
@import "components/cells";
@import "components/cta";
@import "components/carousel";
@import "components/post";
@import "components/partners";
@import "components/directory";
@import "components/footer";
@import "components/map";
@import "components/up";


/**
 * Utils
 */

@import "utils/align";
@import "utils/display";
@import "utils/layout";
@import "utils/position";
@import "utils/size";
@import "utils/space";
@import "utils/text";
