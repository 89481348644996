/* ==========================================================================
   Components.CTA
   ========================================================================== */

.c-Cta {
    @include rem(font-size, 16px);
    font-weight: normal;
}

.c-Cta--inline {
    text-align: center;
}

.c-Cta-item {
    margin-top: 10px;

    .c-Cta--inline & {
        text-align: left;
    }

    @include media(">=medium") {
        .c-Cta--inline & {
            display: inline-block;
            margin: 0 10px;
        }
    }

    @include media(">=large") {
        .c-Cta--inline & {
            margin: 0 25px;
        }
    }
}

.c-Cta-link {
    display: block;
    padding: 0 10px;
    border-bottom: 1px solid #c0c0c0;
    text-decoration: none;

    .c-Cta-item--fill & {
        border: 1px solid;
    }

    > span {
        display: inline-block;
        padding: 20px 0;
        text-decoration: underline;
        vertical-align: middle;
    }

    &:hover {
        .c-Cta--alpha & {
            border-color: $Base-colorAlpha;
            background-color: $Base-colorAlpha;
        }

        .c-Cta--beta & {
            border-color: $Base-colorBeta;
            background-color: $Base-colorBeta;
        }

        .c-Cta--gamma & {
            border-color: $Base-colorGamma;
            background-color: $Base-colorGamma;
        }

        .c-Cta-item--fill & {
            background-color: transparent !important;
            border-color: #333 !important;
        }

        > span {
            text-decoration: none;
        }
    }

    @include media(">=medium") {
        display: inline-block;
        min-width: 320px;
        padding: 0 15px;
    }

    @include media(">=large") {
        min-width: 350px;
        padding: 0 25px;
    }
}

.c-Cta-icon {
    width: 40px;
    margin-right: 10px;

    .c-Cta--alpha & {
        color: $Base-colorAlpha;
    }

    .c-Cta--beta & {
        color: $Base-colorBeta;
    }

    .c-Cta--gamma & {
        color: $Base-colorGamma;
    }

    .c-Cta-item--fill & {
        color: #fff;
    }

    .c-Cta-link:hover & {
        color: #fff;

        .c-Cta-item--fill & {
            color: #333;
        }
    }

    > svg {
        width: auto;
        height: 40px;
    }

    @include media(">=large") {
        margin-right: 20px;
    }
}

.c-Cta-label {
    color: #fff;

    .c-Cta-link:hover & {
        color: #333;
    }
}
