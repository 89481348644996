/* ==========================================================================
   Components.Up
   ========================================================================== */

.c-Up {
    display: block;
    width: 60px;
    height: 55px;
    padding-top: ((55px - 27px) / 2);
    background-color: $Base-colorBeta;
    opacity: 0;
    text-align: center;
    transition: background-color 0.3s, opacity 0.3s, visibility 0.3s;
    visibility: hidden;

    &:hover {
        background-color: #333;
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }

    @include media(">=medium") {
        width: 80px;
        height: 70px;
        padding-top: ((70px - 27px) / 2);
    }
}

.c-Up-icon {
    width: 15px;
    height: 27px;
    color: #fff;
}
