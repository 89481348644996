/* ==========================================================================
   Components.Hero
   ========================================================================== */

.c-Hero {
    padding: (20px + 100px) 0 0;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto 100px;

    @include media(">=medium") {
        padding: 30px 0 30px 45%;
        background-position: 15% center;
        background-size: auto 100%;
    }
}

.c-Hero--solid {
    padding-top: 0;

    @include media(">=medium") {
        padding: 0;
    }
}

.c-Hero--zero {
    @include media(">=medium") {
        padding-top: 0;
        padding-bottom: 0;
    }
}
