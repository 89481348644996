/* ==========================================================================
   Components.Form
   ========================================================================== */

.c-Form {
    position: relative;
    z-index: 10;
}

.c-Form-legend {
    @include rem(font-size, 16px);
    margin-bottom: $Base-unitV;
    font-weight: 600;

    @include media(">=medium") {
        @include rem(font-size, 18px);
    }
}

.c-Form-item {
    margin-bottom: $Base-unitV;

    @include media(">=medium") {
        margin-bottom: 1.5 * $Base-unitV;
    }
}

.c-Form-label {
    display: block;
    margin-bottom: quarter($Base-unitV);
    margin-left: 10px;
    font-weight: normal;
}

.c-Form-label--inline {
    position: relative;
    padding-left: 45px;
    margin: 0;

    .c-Form-radio ~ &,
    .c-Form-checkbox ~ & {
        &:before,
        &:after {
            position: absolute;
        }

        &:before {
            content: "";
            top: -11px;
            left: 0;
            width: 38px;
            height: 38px;
            border: 1px solid #d7d7d7;
        }
    }

    .c-Form-radio ~ & {
        &:before,
        &:after {
            border-radius: 50%;
        }

        &:after {
            top: -3px;
            left: 8px;
            width: 22px;
            height: 22px;
            background-color: #666;
        }
    }

    .c-Form-checkbox ~ & {
        &:after {
            top: -2px;
            left: 6px;
            width: 27px;
            height: 21px;
            background: url('../images/icons/check.svg') no-repeat 0 0;
            background-size: 100% 100%;

            html.no-svg & {
                background-image: url('../images/icons/check.png');
            }
        }
    }

    .c-Form-radio:checked ~ &,
    .c-Form-checkbox:checked ~ & {
        &:after {
            content: "";
        }
    }

    @include media(">=medium") {
        padding-left: 60px;

        .c-Form-radio ~ &,
        .c-Form-checkbox ~ & {
            &:before {
                top: -6px;
            }
        }

        .c-Form-radio ~ & {
            &:after {
                top: 2px;
            }
        }

        .c-Form-checkbox ~ & {
            &:after {
                top: 3px;
            }
        }
    }
}

.c-Form-label--alt {
    @include rem(font-size, 12px);
    margin-left: 0;
    text-align: center;

    @include media(">=medium") {
        @include rem(font-size, 14px);
    }
}

.c-Form-input,
.c-Form-textarea {
    width: 100%;
    padding: 10px;
    margin: 0;
    border: 1px solid #d7d7d7;
    background-color: #fff;
}

.c-Form-block {
    position: relative;
    margin-bottom: 1.5 * $Base-unitV;
}

.c-Form-labelblock {
    display: block;
    padding: 60px 50% 30px 30px;
    cursor: pointer;
    font-weight: 600;

    .c-Form-block--sprava & {
        background-color: #f0f0f0;
    }

    .c-Form-block--aktivity & {
        background-color: #c2e0bf;
    }

    .c-Form-block--helpdesk & {
        background-color: #f7e3b0;
    }

    > span {
        position: relative;
    }

    @include media(">=medium", "<1024px") {
        padding: 30px 30px 100px;

        > span {
            display: block;
        }
    }
}

.c-Form-blockInput {
    position: absolute;
    bottom: 20px;
    right: 30px;
    width: 45%;

    @include media(">=medium", "<1024px") {
        width: 80%;
        right: 10%;
    }
}

.c-Form-table {
    min-height: .01%;
    margin-bottom: double($Base-unitV);
    overflow-x: auto;

    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 0;
        border-collapse: collapse;
        border-spacing: 0;
        overflow-x: auto;

        th,
        td {
            padding: 10px;
            border: 1px solid #d7d7d7;
            text-align: left;
        }

        td {
            vertical-align: top;
        }

        tbody {
            tr:nth-child(odd) td {
                background-color: #f4f4f4;
            }

            tr:hover td {
                background-color: #e9e7e7;
            }
        }
    }

    @include media(">=medium") {
        margin-bottom: ($Base-unitV * 1.5) + $Base-unitV;

        table {
            table-layout: fixed;

            th,
            td {
                padding: 15px 20px;
            }

            th {
                @include rem(font-size, 18px);
            }
        }
    }
}

.c-Form-button {
    padding: 10px 15px;
    border: 1px solid $Base-colorGamma;
    background-color: $Base-colorGamma;
    cursor: pointer;

    > span {
        display: inline-block;
        color: #fff;
        text-decoration: underline;
        vertical-align: middle;
    }

    &:hover {
        border-color: #333;
        background-color: transparent;

        > span {
            color: #333;
            text-decoration: none;
        }
    }

    @include media(">=medium") {
        padding: 20px 30px 20px 25px;
    }

    @include media(">=large") {
        padding: 20px 30px 20px 25px;
    }
}

.c-Form-icon {
    &.icon-submit {
        width: 44px;
        height: 38px;
        margin-right: 5px;
        color: #fff;

        .c-Form-button:hover & {
            color: #333;
        }
    }

    &.icon-check {
        position: absolute;
        bottom: 100%;
        left: 50%;
        display: none;
        width: 44px;
        height: 35px;
        margin-bottom: 5px;
        margin-left: -22px;

        .c-Form-checkblock:checked ~ .c-Form-labelblock & {
            display: block;
        }

        .c-Form-block--sprava & {
            color: $Base-colorAlpha;
        }

        .c-Form-block--aktivity & {
            color: $Base-colorGamma;
        }

        .c-Form-block--helpdesk & {
            color: $Base-colorBeta;
        }
    }

    @include media(">=medium") {
        &.icon-submit {
            margin-right: 15px;
        }
    }

    @include media(">=medium", "<1024px") {
        &.icon-check {
            bottom: 50%;
            right: 0;
            left: auto;
            margin-bottom: -17px;
        }
    }
}
