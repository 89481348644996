/* ==========================================================================
   Components.Map
   ========================================================================== */

.c-Map {
    position: relative;
    color: #333;
    overflow: hidden;

    @include media(">=medium") {
        height: 356px;
    }
}

.c-Map-header {
    position: relative;
    left: -100%;
    width: 200%;
    margin-bottom: 20px;
    text-align: right;

    @include media(">=medium") {
        position: absolute;
        top: 0;
        right: -40%;
        left: auto;
        width: 2000px;
        height: 356px;
        margin-bottom: 0;
    }

    @include media(">=1024px") {
        right: -20%;
    }

    @include media(">=large") {
        right: 0;
    }

    @include media(">=2000px") {
        width: 100%;
    }
}

.c-Map-link {
    display: block;
    background-color: #f0f0f0;
}

.c-Map-body {
    @include media(">=medium") {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 0;

        > .o-Container {
            position: relative;
            height: 0;
        }
    }
}

.c-Map-contact {
    strong {
        @include rem(font-size, 14px);
    }

    @include media(">=medium") {
        position: absolute;
        transform: translateY(-50%);
    }
}

.c-Map-title {
    @include rem(font-size, 22px);
    font-weight: 600;
    line-height: 1.3;

    @include media(">=medium") {
        @include rem(font-size, 28px);
        line-height: 1.2;
    }

    @include media(">=large") {
        @include rem(font-size, 34px);
    }
}
