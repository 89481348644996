/* ==========================================================================
   Components.Gallery
   ========================================================================== */

.c-Gallery {
    margin: 0 (-($Base-containerPadding));

    @include media(">=medium") {
        margin: 0 50px;
    }
}

.c-Gallery-item {
    height: auto;
    padding: 0 5px;
    background-color: #cccccc;
    background-clip: content-box;

    @include media(">=medium") {
        padding: 0 10px;
    }
}

.c-Gallery-link {
    text-decoration: none;
}

.c-Gallery-desc {
    display: block;
    padding: 5px 10px;
    color: #000000;

    @include media(">=medium") {
        padding: 10px 20px;
    }

    @include media(">=large") {
        @include rem(font-size, 16px);
    }
}

.c-Gallery-prev,
.c-Gallery-next {
    position: absolute;
    top: 50%;
    z-index: 5;
    margin-top: -(15px / 2);

    @include media(">=medium") {
        margin-top: -(15px / 2);
    }
}

.c-Gallery-prev {
    @include media(">=medium") {
        left: -50px;
    }
}

.c-Gallery-next {
    @include media(">=medium") {
        right: -50px;
    }
}

.c-Gallery-icon {
    width: 27px;
    height: 15px;
    color: #666;
    transition: color 0.3s;

    .c-Carousel-prev:hover &,
    .c-Carousel-next:hover & {
        color: #000;
    }

}
