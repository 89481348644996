/* ==========================================================================
   Components.Logo
   ========================================================================== */

.c-Logo {
    display: inline-block;
}

.c-Logo-image {
    display: inline-block;
    vertical-align: top;

    @include media("<medium") {
        // width: 80px;
        height: 40px;
    }
}
